/* eslint-disable @next/next/no-img-element */
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import { InformationCircleIcon } from "@heroicons/react/20/solid";
import React from "react";
import Section from "../components/section";
import MetadataCtaButton from "./metadata-cta-button";

export type MetadataProps = ClassProps;

const Metadata = ({ className }: MetadataProps) => {
  return (
    <Section
      className={cx("", className)}
      icon={
        <InformationCircleIcon className="fill-black h-[32px] flex-shrink-0" />
      }
      header={
        <h2 className="text-[54px] leading-[110%] font-righteous">
          Automatic Metadata Tags
        </h2>
      }
      carousel={
        <img
          src="/assets/images/metadata-types.png"
          alt="Metadata types"
          className="flex-1 object-cover px-0 md:ps-[100px] w-full"
        />
      }
      footer={
        <>
          <p>
            Having accurate and detailed metadata helps your music become more
            discoverable, but it&apos;s a tedious and time consuming process.
            Until now.
          </p>

          <p>
            Lutely automatically detects the genre, sub-genre/style, mood,
            tempo, key-signature and vocal type for all of your music.
          </p>

          <MetadataCtaButton />
        </>
      }
    />
  );
};

export default Metadata;
