import { useCallback, useEffect, useRef, useState } from "react";
import { useEffectOnce } from "usehooks-ts";

export type UseAutoLoopParams = {
  interval?: number;
  cycleEndDelay?: number;
  maxIndex: number;
};

const useAutoLoop = ({
  interval,
  maxIndex,
  cycleEndDelay,
}: UseAutoLoopParams) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const intervalId = useRef(0);

  const initTimer = useCallback(() => {
    if (intervalId.current > 0) {
      clearInterval(intervalId.current);
    }

    const newIntervalId = setInterval(() => {
      setActiveIndex((index) => (index + 1) % maxIndex);
    }, interval);
    intervalId.current = newIntervalId as unknown as number;
    return newIntervalId;
  }, [interval, maxIndex, intervalId]);

  useEffectOnce(() => {
    initTimer();
  });

  useEffect(() => {
    if (activeIndex === maxIndex - 1 && cycleEndDelay) {
      clearInterval(intervalId.current);
      setTimeout(() => initTimer(), cycleEndDelay);
    }
  }, [activeIndex, maxIndex, intervalId, cycleEndDelay, initTimer]);

  return activeIndex;
};

export default useAutoLoop;
