import useAudio from "@/lib/hooks/useAudio";
import { noop } from "lodash";
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useState,
} from "react";
import { PlayFunction } from "use-sound/dist/types";

export type MusicContextType = {
  soundUrl: string | null;
  isPlaying: boolean;
  duration: number;
  timeProgress: number;
  play: PlayFunction;
  stop: (id?: string | undefined) => void;
  pause: (id?: string | undefined) => void;
  changeSong: (soundUrl: string) => void;
  setSoundUrl: (url: string) => void;
};

const initialMusicContext: MusicContextType = {
  soundUrl: null,
  isPlaying: false,
  duration: 0,
  timeProgress: 0,
  play: noop,
  stop: noop,
  pause: noop,
  changeSong: noop,
  setSoundUrl: noop,
};

const MusicContext = createContext(initialMusicContext);

export type MusicContextProviderProps = PropsWithChildren;

const handleOnEnded = (audio: HTMLAudioElement) => {
  if (audio) {
    audio.pause();
  }
};

export const MusicContextProvider = ({
  children,
}: MusicContextProviderProps) => {
  const [soundUrl, setSoundUrl] = useState<string | null>(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [duration, setDuration] = useState(0);
  const [timeProgress, setTimeProgress] = useState(0);
  const [intervalId, setIntervalId] = useState(0);

  const onEnded = useCallback(
    (audio: HTMLAudioElement) => {
      setIsPlaying(false);
      handleOnEnded(audio);
      clearInterval(intervalId);
    },
    [intervalId]
  );

  const { audio } = useAudio(soundUrl ?? "", { onEnded });

  const changeSong = (soundUrl: string) => {
    setSoundUrl(soundUrl);
    setTimeout(() => {
      _play();
    }, 0);
  };

  const _play = () => {
    audio?.play();
    setIsPlaying(true);
    setDuration(audio?.duration ?? 0);

    const id = setInterval(() => {
      setTimeProgress((progress) => progress + 1);
    }, 1000) as unknown as number;
    setIntervalId(id);
  };

  const _stop = () => {
    audio?.pause();
    setIsPlaying(false);
    clearInterval(intervalId);
  };

  const _pause = () => {
    audio?.pause();
    setIsPlaying(false);
    clearInterval(intervalId);
  };

  return (
    <MusicContext.Provider
      value={{
        play: _play,
        stop: _stop,
        pause: _pause,
        changeSong,
        soundUrl,
        isPlaying,
        duration,
        timeProgress,
        setSoundUrl,
      }}
    >
      {children}
    </MusicContext.Provider>
  );
};

export const useMusicContext = () => useContext(MusicContext);
