import React from "react";
import { useIsLoggedIn } from "../useIsLoggedIn";
import FilledButton from "@/components/buttons/filled-button";
import Link from "next/link";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";

export type SuperchargeYourMusicCtaButtonProps = ClassProps;

const SuperchargeYourMusicCtaButton = ({
  className,
}: SuperchargeYourMusicCtaButtonProps) => {
  const isLoggedIn = useIsLoggedIn();

  if (isLoggedIn) {
    return (
      <Link href="/account/audiolab" className={cx("", className)}>
        <FilledButton variant="primary" className={cx("font-righteous")}>
          Go to Audiolab
        </FilledButton>
      </Link>
    );
  } else {
    return (
      <Link href="/auth/sign-up" className={cx("", className)}>
        <FilledButton variant="primary" className={cx("font-righteous")}>
          Join Now
        </FilledButton>
      </Link>
    );
  }
};

export default SuperchargeYourMusicCtaButton;
