import { IconType } from "../icon-props";

const MicWithCable: IconType = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="34"
    viewBox="0 0 27 34"
    fill="none"
  >
    <path
      d="M8.7 8.93001H1.3C0.5 8.04668 0 6.88001 0 5.59668C0 2.83001 2.23333 0.59668 5 0.59668C7.76667 0.59668 10 2.83001 10 5.59668C10 6.88001 9.5 8.04668 8.7 8.93001ZM20 0.59668C23.6833 0.59668 26.6667 3.58001 26.6667 7.26335V33.93H23.3333V7.26335C23.3333 5.43001 21.8333 3.93001 20 3.93001C18.1667 3.93001 16.6667 5.43001 16.6667 7.26335V27.2633C16.6667 30.9467 13.6833 33.93 10 33.93C6.31667 33.93 3.33333 30.9467 3.33333 27.2633H1.66667L0 10.5967H10L8.33333 27.2633H6.66667C6.66667 29.0967 8.16667 30.5967 10 30.5967C11.8333 30.5967 13.3333 29.0967 13.3333 27.2633V7.26335C13.3333 3.58001 16.3167 0.59668 20 0.59668Z"
      fill="white"
    />
  </svg>
);

export default MicWithCable;
