import { ArtworkPromptData } from "@/lib/types/ai-artwork";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React from "react";
import Prompt from "./prompt";
import Image from "next/image";

export type ArtworkPromptEntryProps = {
  entry: ArtworkPromptData;
  active: boolean;
} & ClassProps;

const ArtworkPromptEntry = ({
  entry,
  active,
  className,
}: ArtworkPromptEntryProps) => {
  return (
    <div
      className={cx(
        "flex flex-col items-center justify-end gap-[10px] w-full min-w-[280px]",
        className
      )}
    >
      <Prompt
        className={cx(
          "transition-opacity",
          active ? "opacity-100" : "opacity-0"
        )}
      >
        {entry.prompt}
      </Prompt>

      <Image
        src={entry.url}
        alt={entry.prompt}
        className={cx(
          "w-[280px] h-[280px] flex-shrink-0 rounded-[20px] object-cover transition-all",
          active ? "blur-0" : "blur-sm"
        )}
        width={280}
        height={280}
      />
    </div>
  );
};

export default ArtworkPromptEntry;
