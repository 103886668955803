import Image from "next/image";
import Link from "next/link";

interface SocialsProps {
  links: any;
  className?: string;
}

const Socials = ({ links, className }: SocialsProps) => {
  return (
    <div
      className={`relative flex divide-x-1 divide-backgroundLight ${
        className ?? ""
      }`}
    >
      {links.soundcloud && (
        <div className="flex px-3">
          <a
            id="socials-icon-soundcloud"
            href={links.soundcloud}
            className="relative w-6 h-6"
            target="blank"
          >
            <Image
              layout="fill"
              src="/assets/icons/soundcloud.svg"
              className="cursor-pointer"
              alt="soundcloud"
              blurDataURL="/assets/icons/soundcloud.svg"
              placeholder="blur"
            />
          </a>
        </div>
      )}
      {links.youtube && (
        <div className="flex px-3">
          <a
            id="socials-icon-youtube"
            href={links.youtube}
            className="relative w-6 h-6"
            target="blank"
          >
            <Image
              layout="fill"
              src="/assets/icons/youtube.svg"
              className="cursor-pointer"
              alt="youtube"
              blurDataURL="/assets/icons/youtube.svg"
              placeholder="blur"
            />
          </a>
        </div>
      )}
      {links.tiktok && (
        <div className="flex px-3">
          <a
            id="socials-icon-tiktok"
            href={links.tiktok}
            className="relative w-6 h-6"
            target="blank"
          >
            <Image
              layout="fill"
              src="/assets/icons/tiktok.svg"
              className="cursor-pointer"
              alt="tiktok"
              blurDataURL="/assets/icons/tiktok.svg"
              placeholder="blur"
            />
          </a>
        </div>
      )}
      {links.facebook && (
        <div className="flex px-3">
          <a
            id="socials-icon-facebook"
            href={links.facebook}
            className="relative w-6 h-6"
            target="blank"
          >
            <Image
              layout="fill"
              src="/assets/icons/facebook.svg"
              className="cursor-pointer"
              alt="facebook"
              blurDataURL="/assets/icons/facebook.svg"
              placeholder="blur"
            />
          </a>
        </div>
      )}
      {links.instagram && (
        <div className="flex px-3">
          <a
            id="socials-icon-instagram"
            href={links.instagram}
            className="relative w-6 h-6"
            target="blank"
          >
            <Image
              layout="fill"
              src="/assets/icons/instagram.svg"
              className="cursor-pointer"
              alt="instagram"
              blurDataURL="/assets/icons/instagram.svg"
              placeholder="blur"
            />
          </a>
        </div>
      )}
      {links.twitter && (
        <div className="flex px-3">
          <a
            id="socials-icon-x"
            href={links.twitter}
            className="relative w-6 h-6"
            target="blank"
          >
            <Image
              layout="fill"
              src="/assets/icons/x.svg"
              className="cursor-pointer"
              alt="X"
              blurDataURL="/assets/icons/x.svg"
              placeholder="blur"
            />
          </a>
        </div>
      )}
    </div>
  );
};

export default Socials;
