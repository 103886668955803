import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React from "react";
import Icon from "@/components/icons/icon";
import FilledButton from "@/components/buttons/filled-button";
import Section from "../components/section";
import { SongStemsData } from "@/lib/types/stems-and-instrumentals";
import Link from "next/link";
import StemsAndInstrumentalsCtaButton from "./stems-and-instrumentals-cta-button";
import StemsCarousel from "./stems-carousel";
import MicWithCable from "@/components/icons/lib/mic-with-cable";

export type StemsAndInstrumentalsProps = {
  stemsSongs: SongStemsData[];
  changeInterval: number;
} & ClassProps;

const StemsAndInstrumentals = ({
  className,
  stemsSongs,
  changeInterval,
}: StemsAndInstrumentalsProps) => {
  return (
    <Section
      className={cx("bg-electric-blue text-white", className)}
      icon={
        <Icon
          icon={MicWithCable}
          viewBox="0 0 26 26"
          className="fill-black w-[26px] h-[52px]"
        />
      }
      header={
        <h2 className="text-[54px] leading-[110%] font-righteous">
          Stems and Instrumentals
        </h2>
      }
      carousel={
        <StemsCarousel
          songs={stemsSongs}
          changeInterval={changeInterval}
          className=""
        />
      }
      footer={
        <>
          <p>Use stems legally and with permission from our marketplace.</p>

          <p>
            Extract vocals, isolate instrumentals, separate drums, bass, guitar,
            and piano from all your tracks, automatically.
          </p>

          <p>Exports available in MP3, WAV, AIFF, FLAC, M4A, and OGG.</p>
          <div className="flex flex-row items-center gap-5">
            <Link href="/marketplace">
              <FilledButton variant="inverted" className="font-righteous">
                Browse
              </FilledButton>
            </Link>

            <StemsAndInstrumentalsCtaButton />
          </div>
        </>
      }
    />
  );
};

export default StemsAndInstrumentals;
