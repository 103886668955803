import * as React from "react";

function FourSquares(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={570} height={552} fill="none" {...props}>
      <rect
        x={283.814}
        y={551.629}
        width={185}
        height={185}
        rx={20}
        transform="rotate(-135 283.814 551.629)"
        fill="#fff"
      />
      <rect
        x={283.814}
        y={261.629}
        width={185}
        height={185}
        rx={20}
        transform="rotate(-135 283.814 261.629)"
        fill="#fff"
      />
      <g clipPath="url(#prefix__clip0_901_2327)">
        <rect
          x={130.814}
          y={406.629}
          width={185}
          height={185}
          rx={20}
          transform="rotate(-135 130.814 406.629)"
          fill="#fff"
        />
      </g>
      <g clipPath="url(#prefix__clip1_901_2327)">
        <rect
          x={438.814}
          y={406.629}
          width={185}
          height={185}
          rx={20}
          transform="rotate(-135 438.814 406.629)"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0_901_2327">
          <rect
            x={130.814}
            y={406.629}
            width={185}
            height={185}
            rx={20}
            transform="rotate(-135 130.814 406.629)"
            fill="#fff"
          />
        </clipPath>
        <clipPath id="prefix__clip1_901_2327">
          <rect
            x={438.814}
            y={406.629}
            width={185}
            height={185}
            rx={20}
            transform="rotate(-135 438.814 406.629)"
            fill="#fff"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

const FourSquaresIcon = React.memo(FourSquares);
export default FourSquaresIcon;
