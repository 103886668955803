import useAudio from "@/lib/hooks/useAudio";
import { useCurrentAudioUrl, useSyncTime } from "./state";
import { useEffect, useState } from "react";
import { isFunction } from "lodash";

export type UseAudioControllerParams = {
  /**
   * Will use a specific atom for maintaining the currentTime sync between
   * SongPlayer components.
   */
  syncTime?: boolean;
};

/**
 * Hook for handling the controller of one song playing at a time.
 * Internally manages the state for the audio API.
 *
 * @param url is the url of the song to be loaded
 */
export const useAudioController = (
  url: string,
  params?: UseAudioControllerParams
) => {
  // hooks
  const [currentUrl, setCurrentUrl] = useCurrentAudioUrl();
  const [playCurrentTime, setPlayCurrentTime] = useSyncTime();
  const { audio, initialized } = useAudio(url);

  // states
  const [status, setStatus] = useState<"play" | "paused">("paused");
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [intervalId, setIntervalId] = useState(0);

  const normalizedCurrentTime = params?.syncTime
    ? playCurrentTime
    : currentTime;

  const handleSetPlayCurrentTime = (
    time: number | ((currentTime: number) => number)
  ) => {
    if (params?.syncTime) {
      setPlayCurrentTime(time);
    } else {
      setCurrentTime(time);
    }
  };

  const handleSetCurrentTime = (
    newTime: number | ((currentTime: number) => number),
    fromSlide?: boolean
  ) => {
    if (audio) {
      handleSetPlayCurrentTime(newTime);

      if (status === "play" && !fromSlide) {
        return;
      }

      if (isFunction(newTime)) {
        audio!.currentTime = newTime(normalizedCurrentTime);
      } else {
        audio!.currentTime = newTime;
      }
    }
  };

  // Effect: only add event listeners when audio is not yet initialized
  useEffect(() => {
    if (audio && initialized) {
      audio?.addEventListener("play", () => {
        setStatus("play");
      });

      audio?.addEventListener("pause", () => {
        setStatus("paused");
      });

      audio?.addEventListener("loadedmetadata", () => {
        setDuration(audio?.duration);
      });
    }
  }, [audio, initialized, url]);

  useEffect(() => {
    if (status === "play") {
      if (!intervalId) {
        const intervalId = setInterval(() => {
          handleSetPlayCurrentTime((time) => time + 1);
        }, 1000);
        setIntervalId(intervalId as unknown as number);
      }
    } else if (status === "paused") {
      clearInterval(intervalId);
      setIntervalId(0);
    }
    // TODO: need to improve this effect to simplify the deps or make the deps stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [intervalId, status]);

  useEffect(() => {
    if (url !== currentUrl && status === "play") {
      audio?.pause();
      setStatus("paused");
    }
  }, [url, currentUrl, status, audio]);

  useEffect(() => {
    if (audio && params?.syncTime) {
      handleSetCurrentTime(playCurrentTime, false);
    }
    // TODO: need to improve this effect to simplify the deps or make the deps stable
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio, playCurrentTime, params?.syncTime]);

  return {
    status,
    audio,
    currentUrl,
    currentTime: normalizedCurrentTime,
    setCurrentTime: handleSetCurrentTime,
    setCurrentUrl,
    duration,
  };
};
