import * as React from "react";

function PromptTriangle(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg width={88} height={27} fill="none" {...props}>
      <path
        d="M46.541 24.575c-1.596 2.223-4.902 2.223-6.498 0L32.178 13.62A19 19 0 0016.743 5.7H2.851a2.85 2.85 0 110-5.7h81.997a2.85 2.85 0 110 5.7H69.842a19 19 0 00-15.434 7.919l-7.867 10.956z"
        fill="#4D89FF"
      />
    </svg>
  );
}

const PromptTriangleIcon = React.memo(PromptTriangle);
export default PromptTriangleIcon;
