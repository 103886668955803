import React from "react";
import { useIsLoggedIn } from "../useIsLoggedIn";
import FilledButton from "@/components/buttons/filled-button";
import Link from "next/link";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";

export type HeroCtaButtonProps = ClassProps;

const HeroCtaButton = ({ className }: HeroCtaButtonProps) => {
  const isLoggedIn = useIsLoggedIn();

  if (isLoggedIn) {
    return (
      <Link href="/account/audiolab">
        <FilledButton
          variant="primary"
          className={cx(
            "font-righteous !px-[80px] w-full md:w-auto",
            className
          )}
        >
          <span>Go to Audiolab</span>
        </FilledButton>
      </Link>
    );
  } else {
    return (
      <Link href="/auth/sign-up">
        <FilledButton
          variant="primary"
          className={cx(
            "font-righteous !px-[80px] w-full md:w-auto",
            className
          )}
        >
          <span>Get Started For Free</span>
        </FilledButton>
      </Link>
    );
  }
};

export default HeroCtaButton;
