import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React, { useRef } from "react";
import CarouselTransparentButton from "../components/carousel-transparent-button";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { SwiperRef, SwiperSlide } from "swiper/react";
import SingleSlideCarousel from "../components/single-slide-carousel";
import { SongStemsData } from "@/lib/types/stems-and-instrumentals";
import SongStemCard from "./song-stem-card";

export type StemsCarouselProps = {
  songs: SongStemsData[];
  changeInterval: number;
} & ClassProps;

const StemsCarousel = ({
  className,
  songs,
  changeInterval,
}: StemsCarouselProps) => {
  const swiperRef = useRef<SwiperRef>(null);

  return (
    <div
      className={cx(
        "flex flex-row items-center justify-center w-full gap-[10px] text-darkGreen relative",
        className
      )}
    >
      {songs.length > 1 && (
        <CarouselTransparentButton
          onClick={() => swiperRef?.current?.swiper?.slidePrev?.()}
          className="flex-shrink-0 absolute bottom-0 left-0 md:relative z-20 hidden md:flex"
        >
          <ChevronLeftIcon className="fill-green" />
        </CarouselTransparentButton>
      )}

      <SingleSlideCarousel
        ref={swiperRef}
        variant="white"
        autoplay={{ delay: changeInterval }}
        className="w-full md:w-[520px] !mx-0"
        allowTouchMove={false}
        loop={songs.length > 1}
      >
        {songs.map((song) => (
          <SwiperSlide
            key={`song-stems-card-${song.genre}`}
            className="w-full md:w-[520px] flex items-center justify-center px-5"
          >
            {({ isActive }) => <SongStemCard song={song} />}
          </SwiperSlide>
        ))}
      </SingleSlideCarousel>

      {songs.length > 1 && (
        <CarouselTransparentButton
          onClick={() => swiperRef?.current?.swiper?.slideNext?.()}
          className="flex-shrink-0 absolute bottom-0 right-0 md:relative z-20 hidden md:flex"
        >
          <ChevronRightIcon className="fill-green" />
        </CarouselTransparentButton>
      )}
    </div>
  );
};

export default StemsCarousel;
