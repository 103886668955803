import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React from "react";
import { FeaturedArtist } from "@/lib/types/featured-artists";
import Image from "next/image";
import FeaturedArtistsCarousel from "./featured-artists-carousel";
import { InView } from "react-intersection-observer";

export type FeaturedArtistsProps = {
  featuredArtists: FeaturedArtist[];
  changeInterval: number;
  backgroundUrl: string;
} & ClassProps;

const FeaturedArtists = ({
  className,
  featuredArtists,
  changeInterval,
  backgroundUrl,
}: FeaturedArtistsProps) => {
  return (
    <InView triggerOnce>
      {({ inView, ref }) => (
        <>
          <span ref={ref} />
          {inView && (
            <div
              className={cx(
                "flex flex-row w-full items-center py-0 pb-[60px] md:py-20 text-white h-auto overflow-hidden bg-black md:bg-transparent relative",
                className
              )}
            >
              <FeaturedArtistsCarousel
                artists={featuredArtists}
                changeInterval={changeInterval}
                className="z-20 w-full"
              />

              <div className="absolute inset-0 bg-black/40 z-10"></div>

              <Image
                alt="Featured artist background"
                src={backgroundUrl}
                className="absolute h-full min-w-full inset-0 object-cover z-0 flex"
                placeholder="blur"
                blurDataURL="/assets/images/placeholder.png"
                fill
              />
            </div>
          )}
        </>
      )}
    </InView>
  );
};

export default FeaturedArtists;
