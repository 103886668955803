import { ClassProps } from "@/lib/types/class-props";
import React, { useRef } from "react";
import { Autoplay, EffectCoverflow, Pagination } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import { EntityData } from "../../../lib/types/music-licensing";
import EntityCard from "./entity-card";
import { cx } from "@/lib/utils/className.utils";
import CarouselTransparentButton from "../components/carousel-transparent-button";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-coverflow";

export type EntityCarouselProps = {
  entities: EntityData[];
  changeInterval: number;
} & ClassProps;

const EntityCarousel = ({
  entities,
  changeInterval,
  className,
}: EntityCarouselProps) => {
  const swiperRef = useRef<SwiperRef>(null);

  return (
    <div
      className={cx("w-full relative flex flex-col items-center", className)}
    >
      {entities.length > 1 && (
        <div className="absolute w-full h-full items-center justify-center z-10 pointer-events-none hidden md:flex">
          <div className="flex w-[400px] items-center justify-between">
            <CarouselTransparentButton
              onClick={() => swiperRef?.current?.swiper?.slidePrev?.()}
              className="pointer-events-auto"
            >
              <ChevronLeftIcon className="fill-green" />
            </CarouselTransparentButton>

            <CarouselTransparentButton
              onClick={() => swiperRef?.current?.swiper?.slideNext?.()}
              className="pointer-events-auto"
            >
              <ChevronRightIcon className="fill-green" />
            </CarouselTransparentButton>
          </div>
        </div>
      )}

      <Swiper
        ref={swiperRef}
        modules={[EffectCoverflow, Pagination, Autoplay]}
        effect="coverflow"
        coverflowEffect={{
          rotate: 0,
          depth: 180,
          modifier: 4,
          slideShadows: false,
          stretch: 0,
        }}
        centeredSlides
        slidesPerView={2}
        pagination={{
          clickable: true,
          horizontalClass: "flex flex-row items-center justify-center gap-4",
          type: "bullets",
          bulletClass: "swiper-bullet",
          bulletActiveClass: "swiper-bullet-active",
        }}
        autoplay={{
          delay: changeInterval,
        }}
        // We need to disable pointer events on this due to an issue with the
        // coverflow effect and slides that disallow clicks internally.
        className="w-full md:w-[560px] !overflow-visible md:!overflow-hidden [&_.swiper-wrapper]:pointer-events-none"
        navigation
        loop={entities.length > 1}
        loopAdditionalSlides={1}
        watchSlidesProgress
      >
        {entities.map((entity) => (
          <SwiperSlide
            className={cx("flex items-center justify-center group")}
            key={`music-licensing-card-${entity.url}`}
          >
            {({ isActive }) => (
              <EntityCard
                active={isActive}
                entity={entity}
                onClick={() => swiperRef.current!.swiper.autoplay.stop()}
                className={cx(
                  "pointer-events-auto",
                  "group-[.swiper-slide-duplicate-next]:opacity-0",
                  "group-[.swiper-slide-duplicate-prev]:opacity-0",
                  "group-[.swiper-slide-duplicate-active]:opacity-0"
                )}
              />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default EntityCarousel;
