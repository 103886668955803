import { useRouter } from "next/router";
import { useState } from "react";
import PrimaryButton from "@/components/buttons/primary-button";
import { addToNewsletter } from "@/lib/waitlist-manager";
import toast from "react-hot-toast";
import Icon from "@/components/icons/icon";
import Alert from "@/components/icons/lib/alert";
import Check from "@/components/icons/lib/check";

interface NewsletterSubscriptionProps {
  className?: string;
}

const NewsletterSubscription = ({ className }: NewsletterSubscriptionProps) => {
  const router = useRouter();

  const [email, setEmail] = useState("");
  const [sent, setSent] = useState(false);

  const sendEmail = async () => {
    if (email !== "") {
      addToNewsletter(email).then(() => {
        setSent(true);
      });
    } else {
      toast(
        <div className="flex flex-row align-items items-center gap-2">
          <Icon icon={Alert} viewBox="0 0 30 30" size={23} />
          <span>{"Please Input an Email!"}</span>
        </div>,
        { duration: 3 * 1000, position: "top-center" }
      );
    }
  };

  return (
    <div
      className={`flex rounded-full items-center px-4 bg-white h-[56px] md:h-[80px] ${className}`}
    >
      <div className="relative flex items-center border-l-gray-800 h-full w-full px-2">
        <input
          id="home-input-subscribe"
          onChange={(event) => {
            setEmail(event.target.value);
            setSent(false);
          }}
          placeholder="Enter your email address"
          className="bg-transparent w-full border-none focus:ring-0 focus:outline-0 placeholder:text-grayLight"
        />
      </div>

      <PrimaryButton
        id="home-button-subscribe"
        className="font-righteous"
        text={sent ? "Done" : "Go!"}
        disabled={sent}
        customIcon={sent ? <Icon icon={Check} /> : undefined}
        onClick={sendEmail}
      />
    </div>
  );
};

export default NewsletterSubscription;
