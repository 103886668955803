import { cx } from "@/lib/utils/className.utils";
import React, { ButtonHTMLAttributes } from "react";

export type StemButtonProps = {
  active: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const StemButton = ({ active, className, ...props }: StemButtonProps) => {
  return (
    <button
      type="button"
      className={cx(
        "flex flex-col items-center justify-center py-[8.5px] rounded-[10px] gap-[10px]",
        active ? "bg-cream" : "",
        className
      )}
      {...props}
    ></button>
  );
};

export default StemButton;
