import React from "react";
import { useIsLoggedIn } from "../useIsLoggedIn";
import FilledButton from "@/components/buttons/filled-button";
import Link from "next/link";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";

export type MetadataCtaButtonProps = ClassProps;

const MetadataCtaButton = ({ className }: MetadataCtaButtonProps) => {
  const isLoggedIn = useIsLoggedIn();

  if (isLoggedIn) {
    return (
      <Link href="/account/audiolab">
        <FilledButton
          variant="primary"
          className={cx("font-righteous", className)}
        >
          Go to Audiolab
        </FilledButton>
      </Link>
    );
  } else {
    return (
      <Link href="/auth/sign-up">
        <FilledButton
          variant="primary"
          className={cx("font-righteous", className)}
        >
          Get Started
        </FilledButton>
      </Link>
    );
  }
};

export default MetadataCtaButton;
