import Image from "next/image";
import NewsletterSubscription from "./newsletter-subscription";
import { Artist } from "@/models/models";
import { artists } from "@/data/dummy";
import { cx } from "@/lib/utils/className.utils";

interface NewsletterProps {
  className?: string;
}

const Newsletter = ({ className }: NewsletterProps) => {
  const renderItem = (item: Artist, index: number) => {
    return (
      <div
        key={`image-${index}`}
        className="relative h-[150px] w-[150px] md:h-[200px] md:w-[200px] rounded-2xl overflow-hidden"
      >
        <Image
          width={200}
          height={200}
          src={item.profilePicUrl}
          alt="artist-pic"
          placeholder="empty"
        />
        <p className="text-white bottom-0">{item.name}</p>
      </div>
    );
  };

  const renderInclinedCol = (items: Artist[]) => {
    return (
      <div className="space-y-[30px] z-0 rotate-30 ">
        {items.map(renderItem)}
      </div>
    );
  };

  return (
    <div
      className={cx(
        "flex flex-col items-center md:items-start lg:flex-row relative px-4 md:px-normal lg:px-section h-[700px] bg-black overflow-hidden rounded-3xl mx-4 lg:mx-section my-[80px]",
        className
      )}
    >
      <Image
        layout="fill"
        src="/assets/svg/waves.svg"
        alt=""
        className="absolute object-cover inset-0 mt-12 md:my-auto opacity-30"
      />
      {/* Content */}
      <div className="flex flex-col py-8 md:justify-center h-full w-full z-10 text-center md:text-start">
        <h2 className="text-white font-bold text-[48px] leading-[150%] font-righteous">
          Get a <span className="text-green">Free</span> Beat!
        </h2>
        <h2 className="text-white font-bold text-4xl pt-2 lg:text-5xl font-righteous">
          Subscribe to our Newsletter.
        </h2>
        <p className="text-base mt-6 mb-10 text-subtitle md:text-white max-w-[400px] font-normal">
          Stay connected and we&apos;ll send you exclusive discounts, special
          offers, updates and more.
        </p>
        <NewsletterSubscription className="lg:w-[600px]" />
      </div>

      {/* Images */}
      <div className="absolute bottom-[-70px] right-5 md:-right-16 md:top-16 lg:w-2/5 flex md:items-end md:justify-end">
        <div className="hidden md:block">
          {renderInclinedCol([artists.at(2)!, artists.at(3)!, artists.at(4)!])}
        </div>

        <div>{renderInclinedCol([artists.at(0)!, artists.at(1)!])}</div>

        <div className="block md:hidden">
          {renderInclinedCol([artists.at(2)!, artists.at(3)!, artists.at(4)!])}
        </div>
      </div>
    </div>
  );
};

export default Newsletter;
