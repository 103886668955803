import { ClassProps } from "@/lib/types/class-props";
import React, { useEffect } from "react";
import PlayButton from "./play-button";
import { cx } from "@/lib/utils/className.utils";
import WaveControlledProgressSlider from "@/components/audio/wave-controlled-progress-slider";
import { useAudioController } from "../useAudioController";

export type SongCardPlayerProps = {
  url: string;
  title?: string;
  waveformUrl?: string;
  overlayWaveformUrl?: string;

  /**
   * Callback when time is changed.
   */
  onTimeChange?: (time: number) => void;

  /**
   * Called when the audio has been initialized.
   *
   * @param audio the audio element used for playing the URL.
   */
  onInitializedAudio?: (audio: HTMLAudioElement) => void;

  onPlayStatusChange?: (status: "play" | "paused") => void;

  syncTime?: boolean;
} & ClassProps;

const SongPlayer = ({
  title,
  url,
  waveformUrl,
  overlayWaveformUrl,
  className,
  syncTime,
  onTimeChange,
  onInitializedAudio,
  onPlayStatusChange,
}: SongCardPlayerProps) => {
  const {
    status,
    audio,
    setCurrentUrl,
    currentTime,
    setCurrentTime,
    duration,
  } = useAudioController(url, { syncTime });

  const handlePlay = () => {
    if (status === "paused") {
      audio?.play();
      setCurrentUrl(url);
    } else {
      audio?.pause();
    }
  };

  const handleTimeChange = (newTimeString: string) => {
    const newTime = parseInt(newTimeString);
    setCurrentTime(newTime, true);
  };

  useEffect(() => {
    if (audio) {
      onInitializedAudio?.(audio);
    }
  }, [onInitializedAudio, audio]);

  useEffect(() => {
    onTimeChange?.(currentTime);
  }, [currentTime, onTimeChange]);

  useEffect(() => {
    onPlayStatusChange?.(status);
  }, [status, onPlayStatusChange]);

  useEffect(() => {
    return () => {
      // Cleanup function to pause audio playback on component unmounts, for some reason status is paused but audio isn't so just set
      // for pausing always
      if (audio) {
        audio.pause();
      }
    };
  }, [audio]);

  return (
    <div className={cx("flex flex-col items-start gap-5", className)}>
      {title && <p className="hidden md:flex justify-end w-full">{title}</p>}

      <div className="rounded-full w-full bg-darkGreen flex flex-row items-center gap-2 h-[88px] pl-[29px]">
        <PlayButton status={status} size="sm" onClick={handlePlay} />

        <WaveControlledProgressSlider
          className="w-full flex-1 pr-10 py-2 md:pr-0 md:py-0"
          currentTime={currentTime}
          duration={duration}
          waveformUrl={waveformUrl}
          overlayWaveformUrl={overlayWaveformUrl}
          onSlide={handleTimeChange}
        />
      </div>
    </div>
  );
};

export default SongPlayer;
