/* eslint-disable @next/next/no-img-element */
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React, { useEffect } from "react";
import PlayButton from "../components/play-button";
import { useAudioController } from "../useAudioController";
import { slugify } from "@/lib/helpers";
import Image from "next/image";

export type SmallSongCardProps = {
  name: string;
  url: string;
  coverArtUrl: string;
  id: string;
} & ClassProps;

const SmallSongCard = ({
  name,
  url,
  coverArtUrl,
  id,
  className,
}: SmallSongCardProps) => {
  const { status, audio, setCurrentUrl } = useAudioController(url);

  const handlePlay = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (status === "paused") {
      audio?.play();
      setCurrentUrl(url);
    } else {
      audio?.pause();
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup function to pause audio playback on component unmounts, for some reason status is paused but audio isn't so just set
      // for pausing always
      if (audio) {
        audio.pause();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audio]);

  return (
    <a href={`/beats/${slugify(name)}/${id}`}>
      <div
        className={cx(
          "relative w-[143px] h-[143px] group flex flex-col items-center justify-end group",
          className
        )}
      >
        <Image
          src={coverArtUrl}
          alt={name}
          className={cx(
            "w-full h-full object-cover absolute inset-0 rounded-[20px] transition-all z-10"
          )}
          fill
          placeholder="blur"
          blurDataURL="/assets/images/placeholder.png"
        />

        <div className="absolute inset-0 flex items-center justify-center">
          <PlayButton
            size="xs"
            status={status}
            className="transition-all group-hover:opacity-100 opacity-0 z-20"
            onClick={handlePlay}
          />
        </div>

        <div
          className={cx(
            "z-20 bg-gradient-to-b from-black/0 to-black/80 rounded-b-[20px] w-full p-5 absolute bottom-0"
          )}
        >
          <p className="text-[16px] leading-[140%] font-bold truncate">
            {name}
          </p>
        </div>

        <div className="absolute inset-0 bg-black/60 opacity-0 group-hover:opacity-100 z-10 transition-opacity"></div>
      </div>
    </a>
  );
};

export default SmallSongCard;
