import { IconType } from "../icon-props";

const Heart: IconType = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="34"
    viewBox="0 0 33 34"
    fill="none"
  >
    <path
      d="M33 30.0965V4.42985C33 2.41318 31.35 0.763184 29.3333 0.763184H3.66667C1.65 0.763184 0 2.41318 0 4.42985V30.0965C0 32.1132 1.65 33.7632 3.66667 33.7632H29.3333C31.35 33.7632 33 32.1132 33 30.0965ZM10.8167 20.8932L14.6667 25.5315L20.35 18.2165C20.7167 17.7399 21.45 17.7399 21.8167 18.2348L28.2517 26.8148C28.71 27.4198 28.27 28.2815 27.5183 28.2815H5.53667C4.76667 28.2815 4.345 27.4015 4.82167 26.7965L9.38667 20.9299C9.735 20.4532 10.4317 20.4349 10.8167 20.8932Z"
      fill="black"
    />
  </svg>
);

export default Heart;
