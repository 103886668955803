import React, { ForwardedRef, PropsWithChildren, forwardRef } from "react";
import { Autoplay, EffectFade, Navigation } from "swiper";
import { Swiper, SwiperProps, SwiperRef } from "swiper/react";
import "swiper/css/effect-fade";

export type FadeCarouselProps = PropsWithChildren & SwiperProps;

const FadeCarousel = forwardRef<SwiperRef, FadeCarouselProps>(
  ({ children, ...props }: FadeCarouselProps, ref: ForwardedRef<SwiperRef>) => {
    return (
      <Swiper
        ref={ref}
        grabCursor={false}
        modules={[Autoplay, EffectFade, Navigation]}
        slidesPerView={1}
        effect="fade"
        navigation
        slideToClickedSlide
        fadeEffect={{ crossFade: true }}
        {...props}
      >
        {children}
      </Swiper>
    );
  }
);

FadeCarousel.displayName = "FadeCarousel";

export default FadeCarousel;
