import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React from "react";
import Icon from "@/components/icons/icon";
import Section from "../components/section";
import { ArtworkPromptData } from "@/lib/types/ai-artwork";
import AIArtworkCtaButton from "./ai-artwork-cta-button";
import ArtworkPromptCarousel from "./artwork-prompt-carousel";
import Image from "@/components/icons/lib/image";

export type AiArtworkProps = {
  artworkPrompts: ArtworkPromptData[];
  changeInterval: number;
} & ClassProps;

const AiArtwork = ({
  className,
  artworkPrompts,
  changeInterval,
}: AiArtworkProps) => {
  return (
    <Section
      className={cx("", className)}
      icon={
        <Icon
          icon={Image}
          className="fill-black w-[34px] h-[34px]"
          viewBox="0 0 34 34"
        />
      }
      header={
        <h2 className="text-[54px] leading-[110%] font-righteous">
          Generate Cover Artwork
        </h2>
      }
      carousel={
        <ArtworkPromptCarousel
          prompts={artworkPrompts}
          changeInterval={changeInterval}
        />
      }
      footer={
        <>
          <p>
            Make your releases stand out from the crowd with jaw-dropping,
            AI-generated visuals.
          </p>

          <p>Copyright-free and yours to use.</p>

          <AIArtworkCtaButton />
        </>
      }
    />
  );
};

export default AiArtwork;
