import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { cx } from "class-variance-authority";
import React, { useRef } from "react";
import { EffectCoverflow, Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import CarouselTransparentButton from "../components/carousel-transparent-button";
import { ClassProps } from "@/lib/types/class-props";
import { ArtworkPromptData } from "@/lib/types/ai-artwork";
import ArtworkPromptEntry from "./artwork-prompt-entry";

export type ArtworkPromptCarouselProps = {
  prompts: ArtworkPromptData[];
  changeInterval: number;
} & ClassProps;

const ArtworkPromptCarousel = ({
  prompts,
  changeInterval,
  className,
}: ArtworkPromptCarouselProps) => {
  const swiperRef = useRef<SwiperRef>(null);

  return (
    <div
      className={cx("w-full relative flex flex-col items-center", className)}
    >
      <div
        className={cx(
          "absolute w-full h-full items-end md:items-center justify-center z-20 pointer-events-none md:mt-[40px] hidden md:flex"
        )}
      >
        <div className="flex w-[400px] items-center justify-between">
          <CarouselTransparentButton
            onClick={() => swiperRef?.current?.swiper?.slidePrev?.()}
            className="pointer-events-auto"
          >
            <ChevronLeftIcon className="fill-green" />
          </CarouselTransparentButton>

          <CarouselTransparentButton
            onClick={() => swiperRef?.current?.swiper?.slideNext?.()}
            className="pointer-events-auto"
          >
            <ChevronRightIcon className="fill-green" />
          </CarouselTransparentButton>
        </div>
      </div>

      <Swiper
        ref={swiperRef}
        grabCursor={false}
        modules={[EffectCoverflow, Navigation, Pagination, Autoplay]}
        effect="coverflow"
        coverflowEffect={{
          rotate: 0,
          depth: 80,
          modifier: 4,
          slideShadows: false,
        }}
        autoplay={{
          delay: changeInterval,
          disableOnInteraction: true,
        }}
        centeredSlides
        slidesPerView={3}
        pagination={{
          clickable: true,
          horizontalClass: "flex flex-row items-center justify-center gap-4",
          type: "bullets",
          bulletClass: "swiper-bullet",
          bulletActiveClass: "swiper-bullet-active",
        }}
        slideToClickedSlide
        className="w-full"
        navigation
        loop={prompts.length > 1}
      >
        {prompts.map((prompt) => (
          <SwiperSlide
            key={`ai-artwork-card-${prompt.url}`}
            className="w-[280px] flex items-center justify-center"
          >
            {({ isActive }) => (
              <ArtworkPromptEntry active={isActive} entry={prompt} />
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ArtworkPromptCarousel;
