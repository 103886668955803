import Icon from "@/components/icons/icon";
import LocationOutline from "@/components/icons/lib/location-outline";
import { ClassProps } from "@/lib/types/class-props";
import { FeaturedArtist } from "@/lib/types/featured-artists";
import { cx } from "@/lib/utils/className.utils";
import SmallSongCard from "./small-song-card";
import Link from "next/link";
import Image from "next/image";

export type FeaturedArtistSectionProps = {
  artist: FeaturedArtist;
} & ClassProps;

const FeaturedArtistSection = ({
  artist,
  className,
}: FeaturedArtistSectionProps) => {
  const trimmedArtistSongs =
    artist.songs.length >= 4 ? artist.songs?.slice(0, 4) : artist.songs;

  return (
    <div
      className={cx(
        "w-full flex flex-col-reverse gap-10 lg:flex-row items-center pb-[58px] px-[80px]",
        className
      )}
    >
      <div className="w-full lg:w-8/12 flex flex-col items-center md:items-start gap-[44px]">
        <Link href={artist.url}>
          <h1 className="w-full text-center lg:text-start text-[78px] leading-[100%] font-righteous text-white mb-[122px] cursor-pointer">
            {artist.name}
          </h1>
        </Link>
        <div className="flex flex-col items-center md:items-start gap-[10px]">
          <div className="flex flex-row items-center gap-5">
            <span className="flex flex-row items-center gap-[10px]">
              <Icon icon={LocationOutline} />
              <p>
                {artist.location?.city}, {artist.location?.state}
              </p>
            </span>
            <a href={artist.url} className="text-green">
              View Profile
            </a>
          </div>

          <p className="text-[22px] leading-[140%] w-full text-center md:text-start line-clamp-3">
            {artist.description}
          </p>
        </div>

        <div className="flex flex-row items-center gap-[10px]">
          {trimmedArtistSongs.map((song) => (
            <SmallSongCard key={song.id} {...song} className="hidden md:flex" />
          ))}
        </div>
      </div>

      <div className="w-full lg:w-4/12 flex items-center justify-center">
        <Link href={artist.url}>
          <Image
            alt={artist.name}
            src={artist.imageUrl}
            className="rounded-full object-cover z-[100] border-[4px] border-white w-[280px] h-[280px] min-w-[280px] min-h-[280px] max-w-[280px] cursor-pointer"
            width={280}
            height={280}
            placeholder="blur"
            blurDataURL="/assets/images/placeholder.png"
          />
        </Link>
      </div>
    </div>
  );
};

export default FeaturedArtistSection;
