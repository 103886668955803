import { IconType } from "../icon-props";

const Speaker: IconType = (
  <svg
    width="23"
    height="33"
    viewBox="0 0 23 33"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2 0.263184H3.2C1.44 0.263184 0 1.70318 0 3.46318V29.0632C0 30.8232 1.44 32.2472 3.2 32.2472L19.2 32.2632C20.96 32.2632 22.4 30.8232 22.4 29.0632V3.46318C22.4 1.70318 20.96 0.263184 19.2 0.263184ZM11.2 3.46318C12.96 3.46318 14.4 4.90318 14.4 6.66318C14.4 8.42318 12.96 9.86318 11.2 9.86318C9.424 9.86318 8 8.42318 8 6.66318C8 4.90318 9.424 3.46318 11.2 3.46318ZM11.2 29.0632C6.784 29.0632 3.2 25.4792 3.2 21.0632C3.2 16.6472 6.784 13.0632 11.2 13.0632C15.616 13.0632 19.2 16.6472 19.2 21.0632C19.2 25.4792 15.616 29.0632 11.2 29.0632ZM11.2 16.2632C8.544 16.2632 6.4 18.4072 6.4 21.0632C6.4 23.7192 8.544 25.8632 11.2 25.8632C13.856 25.8632 16 23.7192 16 21.0632C16 18.4072 13.856 16.2632 11.2 16.2632Z"
      fill="black"
    />
  </svg>
);

export default Speaker;
