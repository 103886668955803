import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React, { useEffect } from "react";
import PlayButton from "./play-button";
import { useAudioController } from "../useAudioController";
import Image from "next/image";

export type LyricsCardProps = {
  lyrics: string;
  artist: string;
  coverArtUrl: string;
  name: string;
  songUrl: string;
  shadowClassName?: string;
} & ClassProps;

const LyricsCard = ({
  lyrics,
  artist,
  name,
  coverArtUrl,
  songUrl,
  className,
  shadowClassName,
}: LyricsCardProps) => {
  const { status, audio, setCurrentUrl } = useAudioController(songUrl);

  const handlePlay = () => {
    if (status === "paused") {
      audio?.play();
      setCurrentUrl(songUrl);
    } else {
      audio?.pause();
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup function to pause audio playback on component unmounts, for some reason status is paused but audio isn't so just set
      // for pausing always
      if (audio) {
        audio.pause();
      }
    };
  }, [audio]);

  const lines = lyrics.split("\n");

  return (
    <div
      className={cx(
        "flex flex-col gap-[20px] rounded-[20px] bg-white border-[1px] border-solid border-grayLight",
        "p-5 md:px-[30px] md:py-[40px]",
        className
      )}
    >
      <div className="flex flex-row items-center border-b border-solid border-grayLight pb-5 gap-[10px]">
        <Image
          src={coverArtUrl}
          alt={name}
          className="rounded-[8px] w-[80px] h-[80px] object-cover flex-shrink-0"
          width={80}
          height={80}
        />

        <div className="flex flex-col items-start gap-[10px] flex-1">
          <p className="font-bold text-[24px] leading-[140%] text-gray">
            {name}
          </p>

          <p>{artist}</p>
        </div>

        <PlayButton
          status={status}
          size="sm"
          onClick={handlePlay}
          color="invert"
        />
      </div>

      <div className="relative h-full">
        <div
          className={cx(
            "absolute top-0 left-0 right-0 h-[60px] bg-gradient-to-b from-white to-white/0",
            shadowClassName
          )}
        ></div>

        <div
          className={cx(
            "absolute bottom-0 left-0 right-0 h-[60px] bg-gradient-to-t from-white to-white/0",
            shadowClassName
          )}
        ></div>

        <div className="overflow-y-auto h-[320px]">
          {lines.map((line, index) => (
            <p key={index} className="text-start">
              {line}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};

export default LyricsCard;
