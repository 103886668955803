import React, { useEffect, useState } from "react";
import SongPlayer from "../components/song-player";
import { StemData, StemType } from "@/lib/types/stems-and-instrumentals";
import { cx } from "@/lib/utils/className.utils";
import { useCurrentAudioUrl } from "../state";

export type SongStemCardPlayersProps = {
  stems: StemData[];
  activeStemType: StemType;
};

export type AudioMap = {
  [key in StemType]: HTMLAudioElement | null;
};

const isAllAudioPaused = (audioMap: AudioMap) => {
  return (
    audioMap["bass"]?.paused &&
    audioMap["drums"]?.paused &&
    audioMap["instrumental"]?.paused &&
    audioMap["original"]?.paused &&
    audioMap["vocals"]?.paused
  );
};

const SongStemCardPlayers = ({
  stems,
  activeStemType,
}: SongStemCardPlayersProps) => {
  const [, setCurrentUrl] = useCurrentAudioUrl();

  const [originalAudio, setOriginalAudio] =
    useState<HTMLAudioElement | null>(null);
  const [drumsAudio, setDrumsAudio] = useState<HTMLAudioElement | null>(null);
  const [bassAudio, setBassAudio] = useState<HTMLAudioElement | null>(null);
  const [instrumentalAudio, setInstrumentalAudio] =
    useState<HTMLAudioElement | null>(null);
  const [vocalsAudio, setVocalsAudio] = useState<HTMLAudioElement | null>(null);

  const baseWaveformUrl =
    stems.find((stem) => stem.type === "original")?.waveformUrl ?? "";
  const activeStemData = stems.find((stem) => stem.type === activeStemType);

  const originalStemData = stems.find((stem) => stem.type === "original");
  const drumsStemData = stems.find((stem) => stem.type === "drums");
  const bassStemData = stems.find((stem) => stem.type === "bass");
  const instrumentalStemData = stems.find(
    (stem) => stem.type === "instrumental"
  );
  const vocalsStemData = stems.find((stem) => stem.type === "vocals");

  useEffect(() => {
    if (
      !isAllAudioPaused({
        bass: bassAudio,
        drums: drumsAudio,
        instrumental: instrumentalAudio,
        original: originalAudio,
        vocals: vocalsAudio,
      })
    ) {
      switch (activeStemType) {
        case "original":
          originalAudio?.play();
          setCurrentUrl(originalStemData?.url ?? "");
          break;
        case "drums":
          drumsAudio?.play();
          setCurrentUrl(drumsStemData?.url ?? "");
          break;
        case "bass":
          bassAudio?.play();
          setCurrentUrl(bassStemData?.url ?? "");
          break;
        case "instrumental":
          instrumentalAudio?.play();
          setCurrentUrl(instrumentalStemData?.url ?? "");
          break;
        case "vocals":
          vocalsAudio?.play();
          setCurrentUrl(vocalsStemData?.url ?? "");
          break;
        default:
          break;
      }
    }
  }, [
    bassAudio,
    drumsAudio,
    instrumentalAudio,
    originalAudio,
    vocalsAudio,
    activeStemType,
    bassStemData?.url,
    drumsStemData?.url,
    instrumentalStemData?.url,
    originalStemData?.url,
    vocalsStemData?.url,
    setCurrentUrl,
  ]);

  return (
    <>
      <SongPlayer
        url={originalStemData?.url ?? ""}
        waveformUrl={baseWaveformUrl}
        className={cx(
          "mt-10",
          originalStemData?.url === activeStemData?.url ? "block" : "hidden"
        )}
        onInitializedAudio={setOriginalAudio}
        syncTime
      />

      <SongPlayer
        url={drumsStemData?.url ?? ""}
        waveformUrl={baseWaveformUrl}
        overlayWaveformUrl={drumsStemData?.waveformUrl}
        className={cx(
          "mt-10",
          drumsStemData?.url === activeStemData?.url ? "block" : "hidden"
        )}
        onInitializedAudio={setDrumsAudio}
        syncTime
      />

      <SongPlayer
        url={bassStemData?.url ?? ""}
        waveformUrl={baseWaveformUrl}
        overlayWaveformUrl={bassStemData?.waveformUrl}
        className={cx(
          "mt-10",
          bassStemData?.url === activeStemData?.url ? "block" : "hidden"
        )}
        onInitializedAudio={setBassAudio}
        syncTime
      />

      <SongPlayer
        url={instrumentalStemData?.url ?? ""}
        waveformUrl={baseWaveformUrl}
        overlayWaveformUrl={instrumentalStemData?.waveformUrl}
        className={cx(
          "mt-10",
          instrumentalStemData?.url === activeStemData?.url ? "block" : "hidden"
        )}
        onInitializedAudio={setInstrumentalAudio}
        syncTime
      />

      <SongPlayer
        url={vocalsStemData?.url ?? ""}
        waveformUrl={baseWaveformUrl}
        overlayWaveformUrl={vocalsStemData?.waveformUrl}
        className={cx(
          "mt-10",
          vocalsStemData?.url === activeStemData?.url ? "block" : "hidden"
        )}
        onInitializedAudio={setVocalsAudio}
        syncTime
      />
    </>
  );
};

export default SongStemCardPlayers;
