import { ClassProps } from "@/lib/types/class-props";
import { SongStemsData, StemType } from "@/lib/types/stems-and-instrumentals";
import { cx } from "@/lib/utils/className.utils";
import React, { useState } from "react";
import StemButton from "../components/stem-button";
import Icon from "@/components/icons/icon";
import GenreTag from "../components/genre-tag";
import { SyncTimeProvider } from "../state";
import SongStemCardPlayers from "./song-stem-card-players";
import MusicTape from "@/components/icons/lib/music-tape";
import Microphone from "@/components/icons/lib/microphone";
import Drums from "@/components/icons/lib/drums";
import Guitar from "@/components/icons/lib/guitar";
import MusicDisc from "@/components/icons/lib/disc";

const getStemNameByType = (stemType: StemType) => {
  switch (stemType) {
    case "original":
      return "Original";
    case "instrumental":
      return "Instrumental";
    case "vocals":
      return "Vocals";
    case "drums":
      return "Drums";
    case "bass":
      return "Bass";
  }
};

const getIconFromStemType = (stemType: StemType) => {
  switch (stemType) {
    case "original":
      return MusicDisc;
    case "instrumental":
      return MusicTape;
    case "vocals":
      return Microphone;
    case "drums":
      return Drums;
    case "bass":
      return Guitar;
  }
};

export type SongStemCardProps = {
  song: SongStemsData;
} & ClassProps;

const SongStemCard = ({ song, className }: SongStemCardProps) => {
  const [activeStemType, setActiveStemType] = useState<StemType>("original");

  const handleSetActiveStem = (stem: StemType) => {
    setActiveStemType(stem);
  };

  const activeStemData = song.stems.find(
    (stem) => stem.type === activeStemType
  )!;

  return (
    <SyncTimeProvider>
      <div
        className={cx(
          "rounded-[20px] border-solid border-grayLight bg-white flex flex-col-reverse md:flex-col w-full gap-10 shadow-lg",
          "p-5 md:px-[30px] md:py-[40px]",
          className
        )}
      >
        <div className="flex flex-col w-full gap-10 relative">
          <GenreTag
            backgroundColor={song.genreBgColor}
            color={song.genreTextColor}
            className="absolute top-0 left-0"
          >
            {song.genre}
          </GenreTag>

          <SongStemCardPlayers
            stems={song.stems}
            activeStemType={activeStemType}
          />

          <div className="flex flex-row flex-wrap items-center justify-center gap-[10px]">
            {song.stems.map((stem) => (
              <StemButton
                key={stem.url}
                active={activeStemData.type === stem.type}
                onClick={() => handleSetActiveStem(stem.type)}
                className=" min-w-[70px]"
              >
                <Icon
                  viewBox="0 0 65 65"
                  icon={getIconFromStemType(stem.type)}
                  className="w-[36px] h-[36px]"
                />

                <p>{getStemNameByType(stem.type)}</p>
              </StemButton>
            ))}
          </div>
        </div>
      </div>
    </SyncTimeProvider>
  );
};

export default SongStemCard;
