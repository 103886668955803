import { useEffect, useRef } from "react";

/**
 * This hook executes a given function only once per component lifecycle at
 * mount.
 *
 * Use it for general purpose tasks that only need to be performed once.
 *
 * @param callback is the function that will be called only once per component
 * mount.
 */
const useOnMount = (callback: () => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current();
  }, []);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);
};

export default useOnMount;
