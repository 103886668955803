import { ClassProps } from "@/lib/types/class-props";
import React, { ForwardedRef, PropsWithChildren, forwardRef } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperProps, SwiperRef } from "swiper/react";

export type SingleSlideCarouselProps = {
  variant?: "dark" | "white";
} & PropsWithChildren &
  SwiperProps &
  ClassProps;

const SingleSlideCarousel = forwardRef<SwiperRef, SingleSlideCarouselProps>(
  (
    { children, variant = "dark", ...props }: SingleSlideCarouselProps,
    ref: ForwardedRef<SwiperRef>
  ) => {
    const bulletsClassName =
      variant === "dark" ? "swiper-bullet" : "swiper-bullet-light";
    const bulletsActiveClassName =
      variant === "dark"
        ? "swiper-bullet-active"
        : "swiper-bullet-active-light";

    return (
      <Swiper
        ref={ref}
        grabCursor={false}
        modules={[Navigation, Pagination, Autoplay]}
        centeredSlides
        slidesPerView={1}
        pagination={{
          clickable: true,
          horizontalClass: "flex flex-row items-center justify-center gap-4",
          type: "bullets",
          bulletClass: bulletsClassName,
          bulletActiveClass: bulletsActiveClassName,
        }}
        slideToClickedSlide
        navigation
        {...props}
      >
        {children}
      </Swiper>
    );
  }
);

SingleSlideCarousel.displayName = "SingleSlideCarousel";

export default SingleSlideCarousel;
