import React, { useRef } from "react";
import CarouselTransparentButton from "../components/carousel-transparent-button";
import { cx } from "@/lib/utils/className.utils";
import { ClassProps } from "@/lib/types/class-props";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { SwiperRef, SwiperSlide } from "swiper/react";
import SingleSlideCarousel from "../components/single-slide-carousel";
import TranscribeLyricsCard from "./transcribe-lyrics-card";
import { TranscribeLyrics } from "@/lib/types/transcribe-lyrics";

export type LyricsCarouselProps = {
  lyrics: TranscribeLyrics[];
  changeInterval: number;
} & ClassProps;

const LyricsCarousel = ({
  lyrics,
  changeInterval,
  className,
}: LyricsCarouselProps) => {
  const swiperRef = useRef<SwiperRef>(null);

  return (
    <div
      className={cx(
        "flex flex-row items-center justify-center w-full gap-[10px] text-darkGreen relative",
        className
      )}
    >
      {lyrics.length > 1 && (
        <CarouselTransparentButton
          onClick={() => swiperRef?.current?.swiper?.slidePrev?.()}
          className="flex-shrink-0 md:mb-[100px] absolute md:relative bottom-0 left-0 z-20 hidden md:flex"
        >
          <ChevronLeftIcon className="fill-green" />
        </CarouselTransparentButton>
      )}

      <SingleSlideCarousel
        ref={swiperRef}
        autoplay={{ delay: changeInterval }}
        className="w-full md:w-[400px] !mx-0"
        loop={lyrics.length > 1}
      >
        {lyrics.map((lyric) => (
          <SwiperSlide
            className="w-full md:w-[400px] flex items-center justify-center"
            key={`song-stems-card-${lyric.songUrl}`}
            onClick={() => swiperRef.current?.swiper.autoplay.stop()}
          >
            {({ isActive }) => <TranscribeLyricsCard lyrics={lyric} />}
          </SwiperSlide>
        ))}
      </SingleSlideCarousel>

      {lyrics.length > 1 && (
        <CarouselTransparentButton
          onClick={() => swiperRef?.current?.swiper?.slideNext?.()}
          className="flex-shrink-0 md:mb-[100px] absolute md:relative bottom-0 right-0 z-20 hidden md:flex"
        >
          <ChevronRightIcon className="fill-green" />
        </CarouselTransparentButton>
      )}
    </div>
  );
};

export default LyricsCarousel;
