import { ClassProps } from "@/lib/types/class-props";
import React, { useState } from "react";
import { cx } from "@/lib/utils/className.utils";
import { EntityComparisonData } from "@/lib/types/ai-mastering";
import SongPlayer from "../components/song-player";
import StemButton from "../components/stem-button";
import Icon from "@/components/icons/icon";
import GenreTag from "../components/genre-tag";
import { SyncTimeProvider, useCurrentAudioUrl } from "../state";
import Bolt from "@/components/icons/lib/bolt";
import MusicDisc from "@/components/icons/lib/disc";

export type SongStemType = "original" | "mastered";

const getIconFromStemType = (type: SongStemType) => {
  switch (type) {
    case "original":
      return MusicDisc;
    case "mastered":
      return Bolt;
  }
};

export type SongCardProps = {
  entity: EntityComparisonData;
  active?: boolean;
} & ClassProps;

const SongCard = ({ entity, active, className }: SongCardProps) => {
  const [activeStem, setActiveStem] = useState<SongStemType>("original");
  const [, setCurrentUrl] = useCurrentAudioUrl();

  const [originalAudio, setOriginalAudio] = useState<HTMLAudioElement | null>(
    null
  );
  const [masteredAudio, setMasteredAudio] = useState<HTMLAudioElement | null>(
    null
  );

  const handleSetActiveStem = (stem: SongStemType) => {
    setActiveStem(stem);

    if (!originalAudio?.paused || !masteredAudio?.paused) {
      switch (stem) {
        case "original":
          originalAudio?.play();
          setCurrentUrl(entity.normalUrl);
          break;
        case "mastered":
          masteredAudio?.play();
          setCurrentUrl(entity.masteredUrl);
          break;
      }
    }
  };

  const handleOriginalAudioInitialized = (audio: HTMLAudioElement) => {
    setOriginalAudio(audio);
  };

  const handleMasteredAudioInitialized = (audio: HTMLAudioElement) => {
    setMasteredAudio(audio);
  };

  return (
    <SyncTimeProvider>
      <div
        className={cx(
          "w-[520px] flex flex-col items-center gap-[40px] rounded-[20px] border-solid border-[1px] border-grayBorder bg-white px-[30px] py-[40px] shadow-md shadow-gray/40 relative",
          className
        )}
      >
        <GenreTag
          backgroundColor={entity.genreColorBg}
          color={entity.genreColorText}
          className="absolute top-[40px] left-[40px]"
        >
          {entity.genre}
        </GenreTag>

        {/* We need to add a SongPlayer instance and maintain it rendered, so songs can be played and stopped properly. */}
        <SongPlayer
          url={entity.normalUrl}
          className={cx(
            "w-full mt-10",
            activeStem === "original" ? "block" : "hidden"
          )}
          waveformUrl={entity.normalWaveforms}
          onInitializedAudio={handleOriginalAudioInitialized}
          syncTime={true}
        />

        <SongPlayer
          url={entity.masteredUrl}
          className={cx(
            "w-full mt-10",
            activeStem === "mastered" ? "block" : "hidden"
          )}
          waveformUrl={entity.normalWaveforms}
          overlayWaveformUrl={entity.masteredWaveforms}
          onInitializedAudio={handleMasteredAudioInitialized}
          syncTime={true}
        />

        <div className="flex flex-row flex-wrap items-center justify-center gap-[10px]">
          <StemButton
            active={activeStem === "original"}
            onClick={() => handleSetActiveStem("original")}
            className="px-2"
          >
            <Icon
              viewBox="0 0 65 65"
              icon={getIconFromStemType("original")}
              className="w-[36px] h-[36px]"
            />

            <p>Original</p>
          </StemButton>

          <StemButton
            active={activeStem === "mastered"}
            onClick={() => handleSetActiveStem("mastered")}
            className="px-2"
          >
            <Icon
              viewBox="0 0 65 65"
              icon={getIconFromStemType("mastered")}
              className="w-[36px] h-[36px]"
            />

            <p>Mastered</p>
          </StemButton>
        </div>
      </div>
    </SyncTimeProvider>
  );
};

export default SongCard;
