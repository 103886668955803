import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React, { PropsWithChildren } from "react";
import PromptTriangleIcon from "./prompt-triangle";

export type PromptProps = PropsWithChildren & ClassProps;

const Prompt = ({ children, className }: PromptProps) => {
  return (
    <div
      className={cx(
        "flex flex-col items-center justify-end h-[100px]",
        className
      )}
    >
      <p className="rounded-full px-[20px] py-[15px] bg-pale-blue text-white text-center">
        {children}
      </p>
      <PromptTriangleIcon className="-mt-[6px]" />
    </div>
  );
};

export default Prompt;
