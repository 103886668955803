import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React, { PropsWithChildren } from "react";

export type GenreTagProps = {
  backgroundColor: string;
  color: string;
} & PropsWithChildren &
  ClassProps;

const GenreTag = ({
  backgroundColor,
  color,
  children,
  className,
}: GenreTagProps) => {
  return (
    <span
      className={cx(
        "rounded-[8px] px-[10px] flex items-center justify-center h-[22px] text-[12px] leading-[150%] font-bold",
        className
      )}
      style={{
        backgroundColor,
        color,
      }}
    >
      {children}
    </span>
  );
};

export default GenreTag;
