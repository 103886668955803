import Icon from "@/components/icons/icon";
import Subtitles from "@/components/icons/lib/subtitles";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React from "react";
import Section from "../components/section";
import { TranscribeLyrics as TranscribeLyricsType } from "@/lib/types/transcribe-lyrics";
import TranscribeLyricsCtaButton from "./transcribe-lyrics-cta-button";
import LyricsCarousel from "./lyrics-carousel";

export type TranscribeLyricsProps = {
  lyrics: TranscribeLyricsType[];
  changeInterval: number;
} & ClassProps;

const TranscribeLyrics = ({
  className,
  lyrics,
  changeInterval,
}: TranscribeLyricsProps) => {
  return (
    <Section
      className={cx("bg-pale-green", className)}
      icon={
        <Icon
          icon={Subtitles}
          viewBox="0 0 35 28"
          className="fill-black w-[35px] h-[30px]"
        />
      }
      header={
        <h2 className="text-[54px] leading-[110%] font-righteous">
          Transcribe Lyrics
        </h2>
      }
      carousel={
        <LyricsCarousel lyrics={lyrics} changeInterval={changeInterval} />
      }
      footer={
        <>
          <p>
            Transcribe and extract well-structured, time-stamped lyrics within
            95% accuracy.
          </p>

          <p>Formatted for Apple Music and popular DSPs.</p>

          <p>Over 55 languages available.</p>

          <TranscribeLyricsCtaButton />
        </>
      }
      carouselDesktopPosition="right"
    />
  );
};

export default TranscribeLyrics;
