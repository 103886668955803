import HomePage from "@/components/home";
import { getFeaturedArtists } from "@/lib/artists-manager";
import { getHomepageSchema } from "@/lib/constants/home";
import {
  HomepageSchema,
  HomepageSchemaResponse,
} from "@/lib/types/homepage-schema";
import { parseAiMasteringSchema } from "@/lib/utils/homepage/parseAiMasteringSchema";
import { parseArtworkPromptsSchema } from "@/lib/utils/homepage/parseArtworkPromptsSchema";
import { parseFeaturedArtistsSchema } from "@/lib/utils/homepage/parseFeaturedArtistsSchema";
import { parseHeroSchema } from "@/lib/utils/homepage/parseHeroSchema";
import { parseLyricsSchema } from "@/lib/utils/homepage/parseLyricsSchema";
import { parseMusicLicensingSchema } from "@/lib/utils/homepage/parseMusicLicensingSchema";
import { parseRadioEditsSchema } from "@/lib/utils/homepage/parseRadioEditsSchema";
import { parseStemsSchema } from "@/lib/utils/homepage/parseStemsSchema";
import { parseTestimonialsSchema } from "@/lib/utils/homepage/parseTestimonialsSchema";
import { GetStaticProps } from "next";
import Head from "next/head";
import React, { ReactElement } from "react";

export type LutelyProps = {
  schema: HomepageSchema;
};

const Lutely = ({ schema }: LutelyProps) => {
  return (
    <>
      <Head>
        {/* Primary meta tags */}
        <title>Lutely - Giving Music Creators Superpowers</title>
        <meta
          name="title"
          content="Lutely - Giving Music Creators Superpowers"
        />
        <meta
          name="description"
          content="Harness AI tools to enhance your musical creations, sell them on the marketplace commission-free, and buy music for any creative project. All with Lutely"
        />
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://lute.ly" />
        <meta property="og:site_name" content="Lutely" />
        <meta
          property="og:title"
          content="Lutely - Giving Music Creators Superpowers"
        />
        <meta
          property="og:description"
          content="Harness AI tools to enhance your musical creations, sell them on the marketplace commission-free, and buy music for any creative project. All with Lutely"
        />
        <meta
          property="og:image"
          content="https://lute.ly/assets/images/thumbnail.png"
        />

        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content="https://lute.ly" />
        <meta
          property="twitter:title"
          content="Lutely - Giving Music Creators Superpowers"
        />
        <meta
          property="twitter:description"
          content="Harness AI tools to enhance your musical creations, sell them on the marketplace commission-free, and buy music for any creative project. All with Lutely"
        />
        <meta
          property="twitter:image"
          content="https://lute.ly/assets/images/thumbnail.png"
        ></meta>
        <link rel="canonical" href="https://lute.ly" key="canonical" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        ></meta>

        {/* SEO block */}
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{
            __html: `{
                "@context": "http://www.schema.org",
                "@type": "LocalBusiness",
                "name": "Lutely",
                "url": "https://lute.ly/",
                "logo": "https://lute.ly/assets/svg/lutely-complete.svg",
                "image": "https://lute.ly/assets/svg/lutely-complete.svg",
                "description": "Lutely empowers music artists to get discovered, sync license their tracks, and sell beats online. Sign up today and give your music superpowers.",
                "address":
                {
                  "@type": "PostalAddress",
                  "streetAddress": "169 Madison Ave, Suite 2303",
                  "addressLocality": "New York",
                  "addressRegion": "NY",
                  "postalCode": "10016",
                  "addressCountry": "US"
                },
                "telephone": "",
                "priceRange": "USD",
                "openingHours": "Mo, Tu, We, Th, Fr, Sat, Su 24hrs",
                "contactPoint":
                {
                  "@type": "ContactPoint",
                  "telephone": ""
                }
              }`,
          }}
        />
      </Head>

      <HomePage schema={schema} />
    </>
  );
};

const parseHomepageSchemaResponse = async (schema: HomepageSchemaResponse) => {
  const parsed: HomepageSchema = {
    hero: parseHeroSchema(schema.hero),
    marketplace: parseMusicLicensingSchema(schema.marketplace),
    mastering: parseAiMasteringSchema(schema.mastering),
    stems: parseStemsSchema(schema.stems),
    radio: await parseRadioEditsSchema(schema.radio),
    lyrics: await parseLyricsSchema(schema.lyrics),
    artwork: parseArtworkPromptsSchema(schema.artwork),
    featured: parseFeaturedArtistsSchema(schema.featured ?? []),
    testimonials: parseTestimonialsSchema(schema.testimonials),
  };

  return parsed;
};

export const getStaticProps: GetStaticProps = async () => {
  // Featured Creators 5 beats per creator.
  try {
    const featuredArtists = await getFeaturedArtists();
    const rawSchema = await getHomepageSchema();
    const schema = await parseHomepageSchemaResponse({
      ...rawSchema,
      featured: featuredArtists,
    });

    return {
      props: {
        schema,
      },
    };
  } catch (err) {
    const error = err as Error;
    console.error(error.message);
    return {
      props: {
        schema: null,
      },
    };
  }
};

Lutely.getLayout = (page: ReactElement) => {
  return <>{page}</>;
};

export default Lutely;
