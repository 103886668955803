import { IconType } from "../icon-props";

const RadioAlt: IconType = (
  <svg
    width="32"
    height="34"
    viewBox="0 0 32 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28.2857 8.12033H9.9L22.88 2.87176L21.8114 0.263184L1.94857 8.35604C0.801429 8.79604 0 9.9589 0 11.2632V30.1203C0 31.8489 1.39857 33.2632 3.14286 33.2632H28.2857C30.03 33.2632 31.4286 31.8489 31.4286 30.1203V11.2632C31.4286 9.5189 30.03 8.12033 28.2857 8.12033ZM28.2857 11.2632V15.9775H25.1429V12.8346H22V15.9775H3.14286V11.2632H28.2857ZM3.14286 30.1203V19.1203H28.2857V30.1203H3.14286Z"
      fill="black"
    />
    <path
      d="M9.42857 28.5175C11.5983 28.5175 13.3571 26.7587 13.3571 24.589C13.3571 22.4193 11.5983 20.6604 9.42857 20.6604C7.25888 20.6604 5.5 22.4193 5.5 24.589C5.5 26.7587 7.25888 28.5175 9.42857 28.5175Z"
      fill="black"
    />
  </svg>
);

export default RadioAlt;
