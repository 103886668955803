import { useCallback, useEffect, useRef, useState } from "react";

const useAudio = (
  src: string,
  {
    volume,
    playbackRate,
    onEnded,
  }: {
    volume?: number;
    playbackRate?: number;
    onEnded?: (audio: HTMLAudioElement) => void;
  } = {
    volume: 1,
    playbackRate: 1,
  }
) => {
  const audio = useRef<HTMLAudioElement | null>(null);
  const callback = useRef<Function | null>(null);
  const [initialized, setInitialized] = useState(false);

  const setCallback = useCallback(() => {
    callback.current = () => {
      if (audio.current) {
        audio.current.pause();
        onEnded?.(audio.current);
      }
    };
    audio.current?.addEventListener(
      "ended",
      callback.current as EventListenerOrEventListenerObject
    );
  }, [onEnded]);

  const setSrc = useCallback(() => {
    setTimeout(() => {
      try {
        if (audio.current) {
          audio.current.src = src;
        } else {
          audio.current = new Audio();
          audio.current.preload = "none";
          audio.current.autoplay = false;
          audio.current.src = src;
        }
        setInitialized(true);
        audio.current.autoplay = false;
      } catch (err) {
        // Errors on initializing will be ignored
        console.error(err);
      }
    }, 0);
  }, [src]);

  useEffect(() => {
    if (src) {
      setSrc();
      setCallback();
      // audio.current?.play();
      return;
    } else if (!src) {
      return;
    }
  }, [src, onEnded, setSrc, setCallback]);

  useEffect(() => {
    if (audio.current) {
      audio.current.volume = volume ?? 1;
    }
  }, [volume]);

  useEffect(() => {
    if (audio.current) {
      audio.current.playbackRate = playbackRate ?? 1;
    }
  }, [playbackRate]);

  return {
    audio: audio.current,
    initialized,
  };
};

export default useAudio;
