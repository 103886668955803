import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React, { ReactNode } from "react";
import { InView } from "react-intersection-observer";

export type SectionProps = {
  header: ReactNode;
  carousel: ReactNode;
  footer: ReactNode;
  icon: ReactNode;
  carouselDesktopPosition?: "left" | "right";
} & ClassProps;

const Section = ({
  header,
  carousel,
  footer,
  icon,
  carouselDesktopPosition = "left",
  className,
}: SectionProps) => {
  if (carouselDesktopPosition === "left") {
    return (
      <InView triggerOnce>
        {({ inView, ref }) => (
          <>
            <span ref={ref} />
            {inView && (
              <>
                <div
                  className={cx(
                    "hidden md:flex items-center h-[760px]",
                    className
                  )}
                >
                  <div className="flex items-center justify-center flex-1 w-1/2 px-10">
                    {carousel}
                  </div>

                  <div className="flex flex-col items-start gap-10 flex-1 w-1/2 px-[100px]">
                    {icon}
                    {header}
                    {footer}
                  </div>
                </div>

                <div
                  className={cx(
                    "flex md:hidden flex-col items-center justify-center text-center gap-10 py-20 px-10",
                    className
                  )}
                >
                  {header}
                  {carousel}
                  {footer}
                </div>
              </>
            )}
          </>
        )}
      </InView>
    );
  } else {
    return (
      <InView triggerOnce>
        {({ inView, ref }) => (
          <>
            <span ref={ref} />
            {inView && (
              <>
                <div
                  className={cx(
                    "hidden md:flex items-center h-[760px]",
                    className
                  )}
                >
                  <div className="flex flex-col items-start gap-10 flex-1 w-1/2 ps-[100px]">
                    {icon}
                    {header}
                    {footer}
                  </div>

                  <div className="flex items-center justify-center flex-1 w-1/2 px-10">
                    {carousel}
                  </div>
                </div>

                <div
                  className={cx(
                    "flex md:hidden flex-col items-center justify-center text-center gap-10 py-20 px-10",
                    className
                  )}
                >
                  {header}
                  {carousel}
                  {footer}
                </div>
              </>
            )}
          </>
        )}
      </InView>
    );
  }
};

export default Section;
