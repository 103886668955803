import Icon from "@/components/icons/icon";
import LocationOutline from "@/components/icons/lib/location-outline";
import { ClassProps } from "@/lib/types/class-props";
import { FeaturedArtist } from "@/lib/types/featured-artists";
import { cx } from "@/lib/utils/className.utils";
import React from "react";
import Link from "next/link";
import Image from "next/image";

export type FeaturedArtistSectionMobileProps = {
  artist: FeaturedArtist;
} & ClassProps;

const FeaturedArtistSectionMobile = ({
  artist,
  className,
}: FeaturedArtistSectionMobileProps) => {
  return (
    <div
      className={cx(
        "flex flex-col gap-5 items-center w-full text-white",
        className
      )}
    >
      <Link href={artist.url}>
        <Image
          src={artist.imageUrl}
          alt={artist.name}
          className="w-[280px] h-[280px] object-cover rounded-full border-[5px] border-white flex-shrink-0 cursor-pointer"
          width={280}
          height={280}
          placeholder="blur"
          blurDataURL="/assets/images/placeholder.png"
        />
      </Link>
      <Link href={artist.url}>
        <h2 className="text-[28px] leading-[110%] font-righteous cursor-pointer">
          {artist.name}
        </h2>
      </Link>

      <div className="flex flex-col items-center md:items-start gap-[10px]">
        <div className="flex flex-row items-center gap-5">
          <span className="flex flex-row items-center gap-[10px]">
            <Icon icon={LocationOutline} />
            <p>
              {artist.location?.city}, {artist.location?.state}
            </p>
          </span>
          <a href={artist.url} className="text-green">
            View Profile
          </a>
        </div>

        <p className="text-[16px] leading-[140%] w-full md:w-1/2 text-center md:text-start line-clamp-3">
          {artist.description}
        </p>
      </div>
    </div>
  );
};

export default FeaturedArtistSectionMobile;
