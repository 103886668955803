import Link from "next/link";
import { socialMediaLL } from "../../data/dummy";
import Socials from "../socials";
import Image from "next/image";

interface FooterProps {
  safe?: boolean;
}

const Footer = ({ safe }: FooterProps) => {
  return (
    <footer className={`flex w-full justify-evenly bg-white py-8 lg:py-14`}>
      <div
        className={`flex flex-col w-full px-10 lg:px-section ${
          safe ? "max-w-safe" : ""
        }`}
      >
        <div className="flex flex-col lg:flex-row lg:mb-10 justify-between items-center">
          <Link id="footer-icon-home" href="/">
            <Image
              width={140}
              height={50}
              src="/assets/svg/lutely-complete.svg"
              alt="Logo"
              className="cursor-pointer"
              placeholder="empty"
            />
          </Link>
          <div
            className={`flex flex-col md:flex-row gap-4 md:gap-0 mt-8 mb-14 lg:my-14 items-center w-full max-w-[540px] justify-between`}
          >
            <Link id="footer-text-home" href="/">
              <p className="lg:text-gray cursor-pointer">Home</p>
            </Link>
            <a id="footer-text-contact" href="mailto:info@lutely.com">
              <p className="lg:text-gray cursor-pointer">Contact</p>
            </a>
            <Link id="footer-text-privacy" href="/privacy">
              <p className="lg:text-gray cursor-pointer">Privacy Policy</p>
            </Link>
            <Link id="footer-text-terms" href="/terms">
              <p className="lg:text-gray cursor-pointer">Terms of Use</p>
            </Link>
            <Link id="footer-text-policy" href="/dmca">
              <p className="lg:text-gray cursor-pointer">DMCA Policy</p>
            </Link>
          </div>
        </div>

        <div className="flex lg:hidden bg-gradient-to-r from-background mb-10 via-grayLight to-background w-full h-[1px] " />

        <div className="flex flex-col-reverse lg:flex-row gap-10 lg:gap-0 items-center lg:justify-between text-center lg:text-left">
          <div className="flex flex-wrap justify-center">
            <p className="text-gray">© 2024 Lutely</p>
            <p className="text-gray ml-1 flex">
              <span className="hidden md:flex">|</span>&nbsp;All rights reserved
            </p>
          </div>

          {/* Social logos */}
          <Socials links={socialMediaLL} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
