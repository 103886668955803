import React, { ButtonHTMLAttributes, ReactNode } from "react";
import { PlayStatus } from "./types";
import Icon from "@/components/icons/icon";
import { VariantProps, cva, cx } from "class-variance-authority";
import Pause from "@/components/icons/lib/pause";
import Play from "@/components/icons/lib/play";

const icon = cva("flex items-center justify-center rounded-full", {
  variants: {
    size: {
      xs: "w-[32px] h-[32px]",
      sm: "w-[32px] h-[32px]",
      md: "w-[40px] h-[40px]",
    },
    color: {
      default: "fill-darkGreen",
      invert: "fill-white",
    },
  },
  defaultVariants: {
    size: "md",
  },
});

const button = cva(
  "rounded-full flex items-center justify-center cursor-pointer",
  {
    variants: {
      size: {
        xs: "w-[40px] h-[40px]",
        sm: "w-[48px] h-[48px]",
        md: "w-[57px] h-[57px]",
      },
      color: {
        default: "bg-white fill-darkGreen",
        invert: "bg-darkGreen fill-white",
      },
    },
    defaultVariants: {
      size: "md",
    },
  }
);

export type PlayButtonProps = {
  status: PlayStatus;
} & VariantProps<typeof button> &
  ButtonHTMLAttributes<HTMLButtonElement>;

const playButtonIcons: { [key in PlayStatus]: ReactNode } = {
  paused: Play,
  play: Pause,
  stopped: Play,
};

const PlayButton = ({
  status,
  className,
  size = "md",
  color = "default",
  ...rest
}: PlayButtonProps) => {
  return (
    <button
      type="button"
      className={cx(button({ size, color }), "pointer-events-auto", className)}
      {...rest}
    >
      <Icon className={icon({ size, color })} icon={playButtonIcons[status]} />
    </button>
  );
};

export default PlayButton;
