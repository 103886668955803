import { IconType } from "../icon-props";

const LocationOutline: IconType = (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.7" clipPath="url(#clip0_6_806)">
      <path
        d="M12 24.1064L5.636 17.7424C4.37734 16.4837 3.52019 14.8801 3.17293 13.1343C2.82567 11.3884 3.00391 9.57885 3.6851 7.93432C4.36629 6.28979 5.51984 4.88419 6.99988 3.89526C8.47992 2.90633 10.22 2.37849 12 2.37849C13.78 2.37849 15.5201 2.90633 17.0001 3.89526C18.4802 4.88419 19.6337 6.28979 20.3149 7.93432C20.9961 9.57885 21.1743 11.3884 20.8271 13.1343C20.4798 14.8801 19.6227 16.4837 18.364 17.7424L12 24.1064ZM16.95 16.3284C17.9289 15.3494 18.5955 14.1021 18.8656 12.7443C19.1356 11.3864 18.9969 9.97901 18.4671 8.69997C17.9373 7.42093 17.04 6.32772 15.8889 5.55858C14.7378 4.78945 13.3844 4.37892 12 4.37892C10.6156 4.37892 9.26222 4.78945 8.11109 5.55858C6.95996 6.32772 6.06275 7.42093 5.53292 8.69997C5.00308 9.97901 4.86442 11.3864 5.13445 12.7443C5.40449 14.1021 6.07111 15.3494 7.05 16.3284L12 21.2784L16.95 16.3284ZM12 13.3784C11.4696 13.3784 10.9609 13.1677 10.5858 12.7926C10.2107 12.4176 10 11.9088 10 11.3784C10 10.848 10.2107 10.3393 10.5858 9.9642C10.9609 9.58913 11.4696 9.37842 12 9.37842C12.5304 9.37842 13.0391 9.58913 13.4142 9.9642C13.7893 10.3393 14 10.848 14 11.3784C14 11.9088 13.7893 12.4176 13.4142 12.7926C13.0391 13.1677 12.5304 13.3784 12 13.3784Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_6_806">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.378418)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default LocationOutline;
