import { VariantProps, cva, cx } from "class-variance-authority";
import React, { ButtonHTMLAttributes, ReactNode } from "react";

const button = cva("flex items-center justify-center px-[30px]", {
  variants: {
    variant: {
      primary: "bg-green text-darkGreen",
      dark: "bg-darkGreen text-white",
      inverted: "bg-white text-darkGreen",
      secondary: "bg-orange text-darkGreen",
      blue: "bg-blue text-white",
      "dark-blue": "bg-dark-blue text-white border-dark-blue-700",
      pink: "bg-dark-pink text-white",
      "dark-green": "bg-dark-green text-white border-dark-green-700",
      normal: "bg-cream hover:bg-gray-hover border-white",
      black: "bg-black text-white",
      grey: "bg-[#E4E4E4] text-[#747474]",
      clear: "border-[#E4E4E4]",
    },
    decoration: {
      default: "rounded-full",
      outlined: "rounded-full border-[2px] border-solid",
      "non-rounded": "",
    },
    size: {
      default: "h-[50px] min-h-[50px]",
      sm: "h-[36px] min-h-[36px]",
    },
  },
  defaultVariants: {
    variant: "primary",
    decoration: "default",
    size: "default",
  },
});

export type FilledButtonProps = VariantProps<typeof button> &
  ButtonHTMLAttributes<HTMLButtonElement> & {
    leftSlot?: ReactNode;
    rightSlot?: ReactNode;
  };

const FilledButton = ({
  variant,
  decoration,
  size,
  className,
  leftSlot,
  rightSlot,
  children,
  ...props
}: FilledButtonProps) => {
  return (
    <button
      className={cx(button({ variant, decoration, size }), className)}
      {...props}
    >
      {leftSlot}
      {children}
      {rightSlot}
    </button>
  );
};

export default FilledButton;
