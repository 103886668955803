/* eslint-disable @next/next/no-img-element */
import React, { useRef, useState } from "react";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import { HeroArtist } from "@/lib/types/hero";
import { Testimonial } from "@/lib/types/testimonials";
import FadeCarousel from "./fade-carousel";
import { SwiperRef, SwiperSlide } from "swiper/react";
import { getRandomNumberInRange } from "@/lib/utils/getRandomNumberInRange";
import useOnMount from "@/lib/hooks/useOnMount";
import Image from "next/image";

export type ArtistsCarouselProps = {
  artists: HeroArtist[];
  artistsChangeInterval: number;
  quotes: Testimonial[];
} & ClassProps;

const ArtistsCarousel = ({
  artists,
  artistsChangeInterval,
  quotes,
  className,
}: ArtistsCarouselProps) => {
  const profileSwiperRef = useRef<SwiperRef>(null);
  const [quoteActiveIndex, setQuoteActiveIndex] = useState(0);

  const quote = quotes[quoteActiveIndex];

  useOnMount(() => {
    setQuoteActiveIndex(getRandomNumberInRange(0, quotes.length - 1));

    if (profileSwiperRef.current) {
      profileSwiperRef.current?.swiper?.autoplay?.stop();
      // Start interval of profile swiper
      setTimeout(() => {
        if (profileSwiperRef.current) {
          profileSwiperRef.current?.swiper?.autoplay?.start();
        }
      }, artistsChangeInterval / 2);
    }
  });

  return (
    <div className={cx("relative w-full h-full", className)}>
      {/* Background image */}
      <FadeCarousel
        autoplay={{ delay: artistsChangeInterval, disableOnInteraction: true }}
        loop
        className="h-full w-[80%] md:w-[70%] absolute right-0 top-0 bottom-0 z-0 !ml-auto !mr-auto"
      >
        {artists.map((artist) => (
          <SwiperSlide key={`artists-hero-bg-${artist.backgroundUrl}`}>
            <Image
              alt={artist.backgroundUrl}
              src={artist.backgroundUrl}
              className="h-full w-full object-cover rounded-[20px]"
              placeholder="blur"
              blurDataURL="/assets/images/placeholder.png"
              fill
              priority
            />
          </SwiperSlide>
        ))}
      </FadeCarousel>

      {/* Profile image */}
      <FadeCarousel
        ref={profileSwiperRef}
        autoplay={{
          delay: artistsChangeInterval,
          disableOnInteraction: true,
        }}
        loop
        className="z-20 !absolute left-0 bottom-[162px] w-[123px] md:w-[304px] h-[183px] md:h-[362px] !ml-auto !mr-auto"
      >
        {artists.map((artist) => (
          <SwiperSlide key={`artists-hero-fg-${artist.profileUrl}`}>
            <Image
              alt={artist.profileUrl}
              src={artist.profileUrl}
              className="h-full w-full rounded-[20px] object-cover"
              placeholder="blur"
              blurDataURL="/assets/images/placeholder.png"
              fill
              priority
            />
          </SwiperSlide>
        ))}
      </FadeCarousel>

      {/* Quote */}
      <div className="absolute -bottom-5 md:bottom-[25px] w-full md:w-auto flex items-center justify-center z-10">
        <div className="flex gap-[10px] p-5 bg-white bg-opacity-40 rounded-[20px] backdrop-blur-2xl w-full md:w-[485px] mx-10">
          <Image
            alt={quote.name}
            src={quote.imageUrl}
            className="rounded-full object-cover w-[70px] h-[70px] flex-shrink-0"
            placeholder="blur"
            blurDataURL="/assets/images/placeholder.png"
            width={70}
            height={70}
            priority
          />

          <div className="flex flex-col gap-[10px]">
            <p className="italic text-[14px] md:text-[16px] leading-[120%]">
              {quote.quote}
            </p>

            <div className="flex gap-[10px] text-[12px] leading-[140%]">
              <span>{quote.name}</span>
              <span className="text-darkGreen/40">{quote.title}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArtistsCarousel;
