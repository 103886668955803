import { IconType } from "../icon-props";

const Bolt: IconType = (
  <>
    <path d="m51.369 31.579a1 1 0 0 0 -.908-.579h-12.761l2.22-28.923a1 1 0 0 0 -1.76-.723l-25.385 30a1 1 0 0 0 .764 1.646h12.766l-2.225 28.923a1 1 0 0 0 .617 1 .99.99 0 0 0 .38.075 1 1 0 0 0 .763-.354l25.385-30a1 1 0 0 0 .144-1.065zm-25.057 27.414 2.07-26.916a1 1 0 0 0 -1-1.077h-11.682l21.988-25.993-2.07 26.916a1 1 0 0 0 1 1.077h11.69z" />
    <path d="m34.632 13.264a1 1 0 0 0 -1.409.117l-11.823 13.973a1 1 0 0 0 .763 1.646h4.772a1 1 0 1 0 0-2h-2.616l10.43-12.327a1 1 0 0 0 -.117-1.409z" />
    <path d="m30 29h1a1 1 0 0 0 0-2h-1a1 1 0 0 0 0 2z" />
    <path d="m36.214 36.027a1 1 0 0 0 1 1h2.616l-10.43 12.327a1 1 0 1 0 1.526 1.292l11.823-13.973a1 1 0 0 0 -.763-1.646h-4.772a1 1 0 0 0 -1 1z" />
  </>
);

export default Bolt;
