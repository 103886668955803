/* eslint-disable @next/next/no-img-element */
import Image from "next/image";

const formatTime = (secs: number) => {
  if (secs < 0) return "0:00";
  const minutes = Math.floor(secs / 60);
  const returnedMinutes = minutes < 10 && `${minutes}`;
  const seconds = Math.floor(secs % 60);
  const returnedSeconds = seconds < 10 ? `0${seconds}` : `${seconds}`;

  return `${returnedMinutes}:${returnedSeconds}`;
};

interface WaveControlledProgressSliderProps {
  id?: string;
  waveformUrl?: string;
  overlayWaveformUrl?: string;
  duration: number;
  currentTime: number;
  onSlide?: Function;
  thumb?: boolean;
  thumbLine?: boolean;
  className?: string;
}

const WaveControlledProgressSlider = ({
  id,
  waveformUrl,
  overlayWaveformUrl,
  duration,
  currentTime,
  onSlide,
  thumb = false,
  thumbLine = false,
  className,
}: WaveControlledProgressSliderProps) => {
  const isInit = Boolean(duration);

  return (
    <div
      className={`relative flex flex-col lg:flex-row lg:items-center lg:space-x-3 w-full h-full lg:ml-5 lg:mr-10 ${
        className ?? ""
      }`}
    >
      {/* Current time */}
      <p className="hidden lg:flex text-white w-[60px]">
        {formatTime(currentTime)}
      </p>

      {/* Progress Bar */}
      <div className="relative flex h-full w-full">
        <input
          id={id}
          type="range"
          min={0}
          max={duration}
          value={currentTime}
          onChange={(e) => onSlide?.(e.currentTarget.value)}
          className="appearance-none outline-none w-full h-full overflow-hidden audioProgress cursor-pointer rounded-0 md:mr-12 lg:mr-0"
          style={{
            background: `linear-gradient(to right, #1DF364 ${
              isInit ? (currentTime * 100) / duration : 0
            }%, #000 ${isInit ? (currentTime * 100) / duration : 0}% 100%)`,
          }}
        />

        {/* Overlay for comparison */}
        {overlayWaveformUrl && (
          <img
            alt="Overlay waveforms"
            src={overlayWaveformUrl}
            className="absolute h-full object-fill w-full pointer-events-none filter z-10 md:pr-12 lg:pr-0"
            style={{
              filter:
                "invert(46%) sepia(93%) saturate(2731%) hue-rotate(296deg) brightness(99%) contrast(97%)",
            }}
          />
        )}

        <img
          alt="Original waveforms"
          src={waveformUrl || "/assets/images/waveform-desktop.png"}
          className="flex absolute h-full object-fill w-full pointer-events-none invert z-[1] md:pr-12 lg:pr-0"
        />

        {/* Vertical Line */}
        {thumbLine && (
          <div
            className="absolute top-0 w-[1px] h-[80px] bg-gradient-to-b from-green to-black z-10 pointer-events-none"
            style={{ left: `${(currentTime * 100) / duration}%` }}
          />
        )}

        {/* Thumb Slider */}
        {thumb && (
          <div
            className="absolute top-0 -mt-[5px] -ml-[5px] rotate-45 h-[10px] w-[10px] bg-green pointer-events-none"
            style={{ left: `${(currentTime * 100) / duration}%` }}
          />
        )}
      </div>

      {/* Total duration time */}
      <p className="hidden lg:flex text-white w-[60px] justify-end">
        {`-${formatTime(duration - currentTime)}`}
      </p>
    </div>
  );
};

export default WaveControlledProgressSlider;
