import useAutoLoop from "@/lib/hooks/useAutoLoop";
import { cx } from "@/lib/utils/className.utils";
import { Transition } from "@headlessui/react";
import React from "react";

export type WordProps = {
  word: string;
};

const Word = ({ word }: WordProps) => (
  <h1 className="text-[48px] md:text-[56px] lg:text-[78px] leading-[100%] font-righteous">
    {word}
  </h1>
);

export type WordsCarouselProps = {
  className?: string;
  words: string[];
  changeInterval: number;
  /**
   * Milliseconds timeout in which the words carousel will wait when the cycle
   * ends.
   */
  delayCycleEnd: number;
};

const WordsCarousel = ({
  className,
  words,
  changeInterval,
  delayCycleEnd,
}: WordsCarouselProps) => {
  const activeWordIndex = useAutoLoop({
    interval: changeInterval,
    maxIndex: words.length,
    cycleEndDelay: delayCycleEnd,
  });

  return (
    <div className={cx("relative inline-block", className)}>
      {words.map((word, index) => (
        <Transition
          className="absolute"
          key={word}
          show={index === activeWordIndex}
          enterFrom="translate-[200px] opacity-0"
          enterTo="translate-[0px] opacity-100"
          enter="transition-all translate-[0px]"
          leave="transition-all -translate-[200px]"
          leaveFrom="translate-[0px] opacity-100"
          leaveTo="-translate-[200px] opacity-0"
        >
          <Word word={word} />
        </Transition>
      ))}
    </div>
  );
};

export default WordsCarousel;
