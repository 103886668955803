import { IconType } from "../icon-props";

const Subtitles: IconType = (
  <svg
    width="35"
    height="29"
    viewBox="0 0 35 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M31.5 0.763184H3.5C1.575 0.763184 0 2.33818 0 4.26318V25.2632C0 27.1882 1.575 28.7632 3.5 28.7632H31.5C33.425 28.7632 35 27.1882 35 25.2632V4.26318C35 2.33818 33.425 0.763184 31.5 0.763184ZM5.25 14.7632H8.75C9.7125 14.7632 10.5 15.5507 10.5 16.5132C10.5 17.4757 9.7125 18.2632 8.75 18.2632H5.25C4.2875 18.2632 3.5 17.4757 3.5 16.5132C3.5 15.5507 4.2875 14.7632 5.25 14.7632ZM19.25 25.2632H5.25C4.2875 25.2632 3.5 24.4757 3.5 23.5132C3.5 22.5507 4.2875 21.7632 5.25 21.7632H19.25C20.2125 21.7632 21 22.5507 21 23.5132C21 24.4757 20.2125 25.2632 19.25 25.2632ZM29.75 25.2632H26.25C25.2875 25.2632 24.5 24.4757 24.5 23.5132C24.5 22.5507 25.2875 21.7632 26.25 21.7632H29.75C30.7125 21.7632 31.5 22.5507 31.5 23.5132C31.5 24.4757 30.7125 25.2632 29.75 25.2632ZM29.75 18.2632H15.75C14.7875 18.2632 14 17.4757 14 16.5132C14 15.5507 14.7875 14.7632 15.75 14.7632H29.75C30.7125 14.7632 31.5 15.5507 31.5 16.5132C31.5 17.4757 30.7125 18.2632 29.75 18.2632Z"
      fill="black"
    />
  </svg>
);

export default Subtitles;
