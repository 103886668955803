import { ClassProps } from "@/lib/types/class-props";
import { Testimonial } from "@/lib/types/testimonials";
import { cx } from "@/lib/utils/className.utils";
import Image from "next/image";
import React from "react";

export const TESTIMONIAL_WIDTH = 324;

export type TestimonialCardProps = { testimonial: Testimonial } & ClassProps;

const TestimonialCard = ({ testimonial, className }: TestimonialCardProps) => {
  return (
    <div
      className={cx(
        "p-[50px] flex flex-col items-center rounded-[40px] shadow-lg shadow-gray/20 bg-white w-[324px] h-full",
        className
      )}
      style={{ width: TESTIMONIAL_WIDTH }}
    >
      <Image
        src={testimonial.imageUrl}
        alt={testimonial.name}
        className="rounded-full w-[180px] h-[180px] flex-shrink-0 object-cover mb-10"
        width={180}
        height={180}
      />

      <p className="font-righteous font-bold text-[34px] leading-[110%] mb-5 truncate">
        {testimonial.name}
      </p>

      <p className="text-[16px] leading-[140%]">{testimonial.quote}</p>
    </div>
  );
};

export default TestimonialCard;
