import FilledButton from "@/components/buttons/filled-button";
import Icon from "@/components/icons/icon";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React from "react";
import RadioAlt from "@/components/icons/lib/radio-alt";
import Section from "../components/section";
import { LyricsEntity } from "@/lib/types/radio-edits";
import Link from "next/link";
import RadioEditsCtaButton from "./radio-edits-cta-button";
import LyricsCarousel from "./lyrics-carousel";

export type RadioEditsProps = {
  lyrics: LyricsEntity[];
  changeInterval: number;
} & ClassProps;

const RadioEdits = ({ className, lyrics, changeInterval }: RadioEditsProps) => {
  return (
    <Section
      className={cx("bg-pink", className)}
      icon={
        <Icon
          icon={RadioAlt}
          viewBox="0 0 32 34"
          className="fill-black w-[32px] h-[34px]"
        />
      }
      header={
        <h2 className="text-[54px] leading-[110%] font-righteous">
          Automatic Radio Edits
        </h2>
      }
      carousel={
        <LyricsCarousel
          lyrics={lyrics}
          changeInterval={changeInterval}
          className="ms-0 md:ms-[100px]"
        />
      }
      footer={
        <>
          <p>
            Got tracks with explicit lyrics and need them removed? No problem.
          </p>

          <p>
            Instantly create family-friendly and radio-ready versions from any
            song that has explicit lyrics.
          </p>
          <div className="flex flex-row items-center gap-5">
            <Link href="/marketplace">
              <FilledButton variant="inverted" className="font-righteous">
                Browse
              </FilledButton>
            </Link>

            <RadioEditsCtaButton />
          </div>
        </>
      }
      carouselDesktopPosition="right"
    />
  );
};

export default RadioEdits;
