import { IconType } from "../icon-props";

const Drums: IconType = (
  <>
    <g id="Drum_set" data-name="Drum set">
      <path d="M46.41,38H52a1,1,0,0,0,1-1V31a1,1,0,0,0-1-1H41a.969.969,0,0,0-.535.177A13.879,13.879,0,0,0,34,28.051V25h2v2a1,1,0,0,0,1,1h8a1,1,0,0,0,1-1V20a1,1,0,0,0-1-1H37a1,1,0,0,0-1,1v3H30V20a1,1,0,0,0-1-1H20a1,1,0,0,0-1,1v7a1,1,0,0,0,1,1h9a1,1,0,0,0,1-1V25h2v3.051a13.968,13.968,0,0,0-9.96,22.634l-1.935,3.868a1,1,0,0,0,1.79.894l1.6-3.2a13.918,13.918,0,0,0,19.012,0l1.6,3.2a1,1,0,0,0,1.79-.894L43.96,50.685A13.9,13.9,0,0,0,46.41,38ZM38,21h6v5H38ZM28,26H21V21h7Zm23,6v4H45.63a14.068,14.068,0,0,0-2.851-4ZM33,54A12,12,0,1,1,45,42,12.013,12.013,0,0,1,33,54Z" />
      <path d="M33,32A10,10,0,1,0,43,42,10.011,10.011,0,0,0,33,32Zm0,18a8,8,0,1,1,8-8A8.009,8.009,0,0,1,33,50Z" />
      <path d="M17,31H10V12h6a1,1,0,0,0,0-2H12.858a3.981,3.981,0,0,0-7.716,0H2a1,1,0,0,0,0,2H8V31H2a1,1,0,0,0-1,1V46a.977.977,0,0,0,.153.5A.977.977,0,0,0,1,47v8a1,1,0,0,0,2,0V47H8v3.586L4.293,54.293a1,1,0,0,0,1.414,1.414L8,53.414V54a1,1,0,0,0,2,0v-.586l2.293,2.293a1,1,0,0,0,1.414-1.414L10,50.586V47h6v8a1,1,0,0,0,2,0V32A1,1,0,0,0,17,31ZM7.269,10a2,2,0,0,1,3.462,0ZM16,33v2H10V33ZM3,33H8v2H3ZM3,45V37H8v8Zm7-8h6v8H10Z" />
      <path d="M62,22H58.858a3.981,3.981,0,0,0-7.716,0H48a1,1,0,0,0,0,2h4v2a1,1,0,0,0,1,1h1V50.586l-3.707,3.707a1,1,0,0,0,1.414,1.414L54,53.414V54a1,1,0,0,0,2,0v-.586l2.293,2.293a1,1,0,0,0,1.414-1.414L56,50.586V27h1a1,1,0,0,0,1-1V24h4a1,1,0,0,0,0-2Zm-7-1a2,2,0,0,1,1.731,1H53.269A2,2,0,0,1,55,21Zm1,4H54V24h2Z" />
      <path d="M23,24h2a1,1,0,0,0,0-2H23a1,1,0,0,0,0,2Z" />
      <path d="M42,22H40a1,1,0,0,0,0,2h2a1,1,0,0,0,0-2Z" />
      <path d="M47,35h2a1,1,0,0,0,0-2H47a1,1,0,0,0,0,2Z" />
    </g>
  </>
);

export default Drums;
