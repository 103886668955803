import { IconType } from "../icon-props";

const MusicTape: IconType = (
  <>
    <path d="m60 11h-56a3 3 0 0 0 -3 3v36a3 3 0 0 0 3 3h56a3 3 0 0 0 3-3v-36a3 3 0 0 0 -3-3zm-39.461 40 3.11-7h16.7l3.11 7zm40.461-1a1 1 0 0 1 -1 1h-14.35l-3.736-8.406a1 1 0 0 0 -.914-.594h-18a1 1 0 0 0 -.914.594l-3.736 8.406h-14.35a1 1 0 0 1 -1-1v-36a1 1 0 0 1 1-1h56a1 1 0 0 1 1 1z" />
    <path d="m51 24h-38a5.006 5.006 0 0 0 -5 5v6a5.006 5.006 0 0 0 5 5h38a5.006 5.006 0 0 0 5-5v-6a5.006 5.006 0 0 0 -5-5zm3 11a3 3 0 0 1 -3 3h-38a3 3 0 0 1 -3-3v-6a3 3 0 0 1 3-3h38a3 3 0 0 1 3 3z" />
    <path d="m16 27a5 5 0 1 0 5 5 5.006 5.006 0 0 0 -5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1 -3 3z" />
    <path d="m48 27a5 5 0 1 0 5 5 5.006 5.006 0 0 0 -5-5zm0 8a3 3 0 1 1 3-3 3 3 0 0 1 -3 3z" />
    <path d="m39 27h-14a1 1 0 0 0 -1 1v8a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-8a1 1 0 0 0 -1-1zm-1 8h-12v-6h12z" />
    <path d="m6 22h6a1 1 0 0 0 1-1v-5a1 1 0 0 0 -1-1h-6a1 1 0 0 0 -1 1v5a1 1 0 0 0 1 1zm1-5h4v3h-4z" />
    <path d="m58 15h-43a1 1 0 0 0 -1 1v5a1 1 0 0 0 1 1h43a1 1 0 0 0 1-1v-5a1 1 0 0 0 -1-1zm-1 5h-41v-3h41z" />
    <path d="m10 48h-5a1 1 0 0 0 0 2h5a1 1 0 0 0 0-2z" />
    <path d="m14 48h-1a1 1 0 0 0 0 2h1a1 1 0 0 0 0-2z" />
  </>
);

export default MusicTape;
