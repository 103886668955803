import { EntityComparisonData } from "@/lib/types/ai-mastering";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React, { useRef } from "react";
import { SwiperRef, SwiperSlide } from "swiper/react";
import SongCard from "./song-card";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import CarouselTransparentButton from "../components/carousel-transparent-button";
import SingleSlideCarousel from "../components/single-slide-carousel";

export type SongsCarouselProps = {
  entities: EntityComparisonData[];
  changeInterval: number;
} & ClassProps;

const SongsCarousel = ({
  className,
  entities,
  changeInterval,
}: SongsCarouselProps) => {
  const swiperRef = useRef<SwiperRef>(null);

  return (
    <div
      className={cx(
        "flex flex-row items-center justify-center w-full gap-[10px] relative",
        className
      )}
      onClick={() => swiperRef.current!.swiper.autoplay.stop()}
    >
      {entities.length > 1 && (
        <CarouselTransparentButton
          onClick={() => swiperRef?.current?.swiper?.slidePrev?.()}
          className="flex-shrink-0 absolute bottom-0 left-0 md:relative z-20 hidden md:flex"
        >
          <ChevronLeftIcon className="fill-green" />
        </CarouselTransparentButton>
      )}

      <SingleSlideCarousel
        ref={swiperRef}
        className="w-full md:w-[520px] !mx-[0]"
        autoplay={{ delay: changeInterval, disableOnInteraction: true }}
        allowTouchMove={false}
        loop={entities.length > 1}
      >
        {entities.map((entity) => (
          <SwiperSlide
            key={`music-licensing-card-${entity.normalUrl}`}
            className="w-full md:w-[520px] flex items-center justify-center"
          >
            {({ isActive }) => <SongCard active={isActive} entity={entity} />}
          </SwiperSlide>
        ))}
      </SingleSlideCarousel>

      {entities.length > 1 && (
        <CarouselTransparentButton
          onClick={() => swiperRef?.current?.swiper?.slideNext?.()}
          className="flex-shrink-0 absolute bottom-0 right-0 md:relative z-20 hidden md:flex"
        >
          <ChevronRightIcon className="fill-green" />
        </CarouselTransparentButton>
      )}
    </div>
  );
};

export default SongsCarousel;
