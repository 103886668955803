import { atom, useAtom, Provider } from "jotai";
import { ScopeProvider } from "jotai-scope";
import { isFunction } from "lodash";
import { PropsWithChildren } from "react";

const currentUrlAtom = atom<string | null>(null);
export const useCurrentAudioUrl = () => useAtom(currentUrlAtom);

export type CurrentTimeMap = Record<string, number>;

const currentTimeMapAtom = atom<CurrentTimeMap>({});
export const useCurrentTimeMap = () => useAtom(currentTimeMapAtom);
export const usePlayCurrentTime = (id: string) => {
  const [map, setMap] = useCurrentTimeMap();

  const handleSetCurrentTime = (time: number | ((time: number) => number)) => {
    if (isFunction(time)) {
      setMap((map) => {
        const oldValue = map[id] ?? 0;
        const newValue = time(map[id] ?? 0);

        if (oldValue !== newValue) {
          const copy = { ...map, [id]: newValue };
          return copy;
        } else {
          return map;
        }
      });
    } else {
      setMap((map) => {
        if (map[id] !== time) {
          const copy = { ...map, [id]: time };
          return copy;
        } else {
          return map;
        }
      });
    }
  };

  return {
    currentTime: map[id] ?? 0,
    setCurrentTime: handleSetCurrentTime,
  };
};

const syncTimeAtom = atom(0);
export const useSyncTime = () => useAtom(syncTimeAtom);

export type SyncTimeProviderProps = PropsWithChildren;

/**
 * Provider that maintain will have a slice of different current times according to the
 * provider subtree position. This will help in case we need to have different
 * synchronizations between elements.
 *
 * Intended to use with atom usage on SongPlayer components.
 *
 * @param props is the props of this provider (children)
 * @returns
 */
export const SyncTimeProvider = ({ children }: SyncTimeProviderProps) => {
  return <ScopeProvider atoms={[syncTimeAtom]}>{children}</ScopeProvider>;
};
