import React from "react";
import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import FourSquaresIcon from "../icons/four-squares";
import { HeroArtist } from "@/lib/types/hero";
import { Testimonial } from "@/lib/types/testimonials";
import HeroCtaButton from "./hero-cta-button";
import WordsCarousel from "./words-carousel";
import ArtistsCarousel from "./artists-carousel";

export type HeroProps = {
  heroArtists: HeroArtist[];
  heroArtistsChangeInterval: number;
  words: string[];
  wordsChangeInterval: number;
  wordsDelayCycleEnd: number;
  quotes: Testimonial[];
  quotesChangeInterval: number;
} & ClassProps;

const Hero = ({
  className,
  heroArtists,
  heroArtistsChangeInterval,
  words,
  wordsChangeInterval,
  wordsDelayCycleEnd,
  quotes,
}: HeroProps) => {
  return (
    <div className={cx("flex flex-col relative", className)}>
      {/* Bg images */}
      <FourSquaresIcon className="absolute -left-[250px] bottom-[155px] z-0" />
      <FourSquaresIcon className="absolute -right-[250px] -bottom-[250px] z-0" />

      {/* Top section */}
      <div className="flex flex-col-reverse md:flex-row z-10">
        {/* Text container */}
        <div
          className={cx(
            "w-full md:w-[50%] flex-1 flex flex-col gap-5 justify-center items-start py-5",
            "px-5 md:ps-[100px]"
          )}
        >
          <div className="w-full md:w-auto flex flex-col items-start">
            <h1 className="text-[48px] md:text-[56px] lg:text-[78px] leading-[100%] font-righteous">
              Giving
            </h1>

            <WordsCarousel
              words={words}
              changeInterval={wordsChangeInterval}
              delayCycleEnd={wordsDelayCycleEnd}
              className="h-[48px] md:h-[56px] lg:h-[78px] leading-[100%]"
            />

            <h1 className="text-[48px] md:text-[56px] lg:text-[78px] leading-[100%]  font-righteous bg-gradient-to-b from-green to-blue inline-block text-transparent bg-clip-text">
              Superpowers
            </h1>
          </div>

          <p className="text-darkGreen text-opacity-70 font-open-sans font-normal">
            Upload your tracks and unlock mastering, stems, lyrics,
            instrumentals, radio edits, and album artwork. <br /> <br />
            Publish to our marketplace and license beats, songs, and sounds
            commission-free.
          </p>

          <HeroCtaButton />
        </div>

        {/* Images container */}
        <div className="flex w-full md:w-[50%] relative h-[50vh] md:h-[80vh] px-5 py-5">
          <ArtistsCarousel
            artists={heroArtists}
            artistsChangeInterval={heroArtistsChangeInterval}
            quotes={quotes}
            className="w-full h-full"
          />
        </div>
      </div>

      {/* Bottom section */}
      <div className="flex flex-col items-center pt-[200px] pb-[60px] px-5 md:px-0">
        <h2 className="font-righteous leading-[54px] text-[54px] text-center mb-5 w-full z-20">
          Abso
          <span className="bg-gradient-to-b from-green to-blue inline-block text-transparent bg-clip-text">
            lutely
          </span>{" "}
          everything to <br />
          unlock your creative genius
        </h2>

        <p className="text-[22px] leading-[140%] mb-[100px] text-darkGreen/70 z-20 text-center md:text-start">
          Lutely is the all-in-one music platform to manage and monetize your
          creative works
        </p>

        <p className="text-[12px] leading-[140%] z-20">Scroll for more</p>

        <ChevronDownIcon className="w-[34px] h-auto z-20" />
      </div>
    </div>
  );
};

export default Hero;
