import { ClassProps } from "@/lib/types/class-props";
import React from "react";
import Icon from "@/components/icons/icon";
import Speaker from "@/components/icons/lib/speaker";
import Section from "../components/section";
import { EntityComparisonData } from "@/lib/types/ai-mastering";
import AIMasteringCtaButton from "./ai-mastering-cta-button";
import SongsCarousel from "./songs-carousel";

export type AiMasteringProps = {
  aiMasteringEntities: EntityComparisonData[];
  changeInterval: number;
} & ClassProps;

const AiMastering = ({
  className,
  aiMasteringEntities,
  changeInterval,
}: AiMasteringProps) => {
  return (
    <Section
      className={className}
      icon={
        <Icon icon={Speaker} className="fill-black h-[32px] flex-shrink-0" />
      }
      header={
        <h2 className="text-[54px] leading-[110%] font-righteous text-darkGreen">
          Unlimited AI Mastering
        </h2>
      }
      carousel={
        <SongsCarousel
          entities={aiMasteringEntities}
          changeInterval={changeInterval}
        />
      }
      footer={
        <>
          <p>
            Get your music commercially ready and ensure your tracks translate
            well across ALL playback systems - without breaking the bank.
          </p>

          <p>
            Lutley&apos;s mastering balances frequencies and controls the
            dynamic range in your tracks so you stay competitively loud without
            distorting or clipping.
          </p>

          <AIMasteringCtaButton />
        </>
      }
      carouselDesktopPosition="right"
    />
  );
};

export default AiMastering;
