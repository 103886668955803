import { ClassProps } from "@/lib/types/class-props";
import { Testimonial } from "@/lib/types/testimonials";
import { cx } from "@/lib/utils/className.utils";
import React, { useRef } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import TestimonialCard from "./testimonial-card";
import "swiper/css";
import CarouselTransparentButton from "../components/carousel-transparent-button";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";

export type TestimonialsCarouselProps = {
  testimonials: Testimonial[];
  changeInterval: number;
} & ClassProps;

const TestimonialsCarousel = ({
  testimonials,
  changeInterval,
  className,
}: TestimonialsCarouselProps) => {
  const ref = useRef<SwiperRef>(null);

  return (
    <div className={cx("w-[100vw] relative", className)}>
      <Swiper
        ref={ref}
        grabCursor={false}
        modules={[Navigation, Pagination, Autoplay]}
        slidesPerView={"auto"}
        grid={{
          fill: "column",
          rows: 1,
        }}
        centeredSlides
        pagination={{
          clickable: true,
          horizontalClass: "flex flex-row items-center justify-center gap-4",
          type: "bullets",
          bulletClass: "swiper-bullet",
          bulletActiveClass: "swiper-bullet-active",
        }}
        breakpoints={{
          640: { slidesPerView: 1 },
          768: {
            slidesPerView: 1.5,
            centerInsufficientSlides: true,
          },
          1108: {
            slidesPerView: 3,
          },
          1500: {
            slidesPerView: 3,
          },
        }}
        spaceBetween={20}
        autoplay={{ delay: changeInterval, disableOnInteraction: true }}
        navigation
        loop={testimonials.length > 1}
        className="!mx-auto max-w-[1000px]"
      >
        {testimonials.map((testimonial) => (
          <SwiperSlide
            key={testimonial.imageUrl}
            className="flex items-center justify-center mb-5 h-full"
          >
            <TestimonialCard testimonial={testimonial} />
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Navigation buttons */}
      <div className="absolute bottom-0 md:bottom-1/2 w-full items-center justify-between px-10 hidden md:flex">
        <CarouselTransparentButton
          className="z-30"
          onClick={() => ref.current?.swiper?.slidePrev?.()}
        >
          <ChevronLeftIcon className="fill-green" />
        </CarouselTransparentButton>

        <CarouselTransparentButton
          className="z-30"
          onClick={() => ref.current?.swiper?.slideNext?.()}
        >
          <ChevronRightIcon className="fill-green" />
        </CarouselTransparentButton>
      </div>
    </div>
  );
};

export default TestimonialsCarousel;
