import { IconType } from "../icon-props";

const MusicDisc: IconType = (
  <>
    <g id="Vinyl-2" data-name="Vinyl">
      <path d="M32,1A31,31,0,1,0,63,32,31.035,31.035,0,0,0,32,1Zm0,60A29,29,0,1,1,61,32,29.033,29.033,0,0,1,32,61Z" />
      <path d="M36,32a4,4,0,1,0-4,4A4,4,0,0,0,36,32Zm-6,0a2,2,0,1,1,2,2A2,2,0,0,1,30,32Z" />
      <path d="M32,17A15,15,0,1,0,47,32,15.017,15.017,0,0,0,32,17Zm0,28A13,13,0,1,1,45,32,13.015,13.015,0,0,1,32,45Z" />
      <path d="M33,6a1,1,0,0,0-1-1A26.975,26.975,0,0,0,5.839,25.3a1,1,0,1,0,1.937.5A24.977,24.977,0,0,1,32,7,1,1,0,0,0,33,6Z" />
      <path d="M6.284,28.006a.991.991,0,0,0-1.106.88A27.5,27.5,0,0,0,5,32a1,1,0,0,0,2,0,25.829,25.829,0,0,1,.164-2.887A1,1,0,0,0,6.284,28.006Z" />
      <path d="M41.193,36.067a1,1,0,0,0-1.363.375A9.03,9.03,0,0,1,32,41a1,1,0,0,0,0,2,11.029,11.029,0,0,0,9.568-5.57A1,1,0,0,0,41.193,36.067Z" />
      <path d="M40.817,33.815a1,1,0,0,0,.78,1.18.979.979,0,0,0,.2.02,1,1,0,0,0,.978-.8A11.038,11.038,0,0,0,43,32a1,1,0,0,0-2,0A9.13,9.13,0,0,1,40.817,33.815Z" />
    </g>
  </>
);

export default MusicDisc;
