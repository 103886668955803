import { ClassProps } from "@/lib/types/class-props";
import { FeaturedArtist } from "@/lib/types/featured-artists";
import { cx } from "@/lib/utils/className.utils";
import React, { useRef } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import FeaturedArtistSection from "./featured-artist-section";
import CarouselTransparentButton from "../components/carousel-transparent-button";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import FeaturedArtistSectionMobile from "./featured-artist-section-mobile";

export type FeaturedArtistsCarouselProps = {
  artists: FeaturedArtist[] | null;
  changeInterval: number;
  onArtistChange?: (artist: FeaturedArtist) => void;
} & ClassProps;

const FeaturedArtistsCarousel = ({
  artists,
  changeInterval,
  onArtistChange,
  className,
}: FeaturedArtistsCarouselProps) => {
  const ref = useRef<SwiperRef>(null);

  return (
    <div
      className={cx("w-full h-full relative", className)}
      onClick={() => ref.current!.swiper.autoplay.stop()}
    >
      {/* Featured artist title (only on mobile) */}
      <div className="w-full flex items-center justify-center md:justify-start px-10 md:px-[80px] pt-10 md:pt-0">
        <h3 className="font-righteous text-white text-[28px] leading-[110%] md:text-[34px]">
          Featured Artists
        </h3>
      </div>

      {(artists?.length ?? 0) > 1 && (
        <div className="absolute w-full h-full items-end md:items-center justify-center z-20 pointer-events-none px-[32px] pb-[64px] md:pb-0 hidden md:flex">
          <div className="flex w-full items-center justify-between">
            <CarouselTransparentButton
              size="md"
              color="gray"
              onClick={() => ref?.current?.swiper?.slidePrev?.()}
              className="pointer-events-auto bg-opacity-10"
            >
              <ChevronLeftIcon className="fill-green" />
            </CarouselTransparentButton>

            <CarouselTransparentButton
              size="md"
              color="gray"
              onClick={() => ref?.current?.swiper?.slideNext?.()}
              className="pointer-events-auto bg-opacity-10"
            >
              <ChevronRightIcon className="fill-green" />
            </CarouselTransparentButton>
          </div>
        </div>
      )}

      <Swiper
        ref={ref}
        className="mt-[60px] md:mt-0"
        grabCursor={false}
        modules={[Navigation, Pagination, Autoplay]}
        centeredSlides
        slidesPerView={1}
        pagination={{
          clickable: true,
          horizontalClass: "flex flex-row items-center justify-center gap-4",
          type: "bullets",
          bulletClass: "swiper-bullet-light",
          bulletActiveClass: "swiper-bullet-active-green",
        }}
        autoplay={{ delay: changeInterval, disableOnInteraction: true }}
        slideToClickedSlide
        navigation
        onSlideChange={(swiper) =>
          onArtistChange?.(artists?.[swiper.activeIndex]!)
        }
        allowTouchMove={true}
        loop={(artists?.length ?? 0) > 1}
      >
        {artists?.map((artist) => (
          <SwiperSlide
            key={`featured-artist-slide-${artist.url}`}
            className="w-full"
          >
            {({ isActive }) => (
              <>
                <FeaturedArtistSection
                  artist={artist}
                  className="hidden md:flex"
                />
                <FeaturedArtistSectionMobile
                  artist={artist}
                  className="flex md:hidden"
                />
              </>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default FeaturedArtistsCarousel;
