import { cx } from "@/lib/utils/className.utils";
import { VariantProps, cva } from "class-variance-authority";
import React, { ButtonHTMLAttributes } from "react";

const button = cva(
  "rounded-full bg-darkGreen bg-opacity-40 backdrop-blur-[35px] fill-green",
  {
    variants: {
      size: {
        sm: "w-[32px] h-[32px]",
        md: "w-[40px] h-[40px]",
        lg: "w-[80px] h-[80px]",
      },
      color: {
        default: "bg-dark-green bg-opacity-10",
        gray: "bg-gray bg-opacity-10",
      },
    },
    defaultVariants: {
      size: "md",
      color: "default",
    },
  }
);

export type CarouselTransparentButtonProps = VariantProps<typeof button> &
  ButtonHTMLAttributes<HTMLButtonElement>;

const CarouselTransparentButton = ({
  size,
  color,
  className,
  ...rest
}: CarouselTransparentButtonProps) => {
  return (
    <button
      type="button"
      className={cx("", button({ size, color }), className)}
      {...rest}
    ></button>
  );
};

export default CarouselTransparentButton;
