import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React from "react";
import LyricsCard from "../components/lyrics-card";
import { TranscribeLyrics } from "@/lib/types/transcribe-lyrics";

export type TranscribeLyricsCardProps = {
  lyrics: TranscribeLyrics;
} & ClassProps;

const TranscribeLyricsCard = ({
  lyrics,
  className,
}: TranscribeLyricsCardProps) => {
  return (
    <div className={cx("flex flex-col items-center gap-5", className)}>
      <div className="flex center justify-center gap-2">
        <p className="font-righteous text-[40px] text-shadow-sm text-shadowDark mt-2">
          {lyrics.languageIsoCode}
        </p>
        <p> &mdash; </p>
        <p className="text-[12px]">{lyrics.language}</p>
      </div>

      <LyricsCard
        className="border-[1px] border-solid border-grayBorder shadow-md w-full md:max-w-[400px] overflow-hidden"
        {...lyrics}
      />
    </div>
  );
};

export default TranscribeLyricsCard;
