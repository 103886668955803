import { IconType } from "../icon-props";

const Guitar: IconType = (
  <>
    <g id="Electric_guitar" data-name="Electric guitar">
      <path d="M29,51h6a1,1,0,0,0,1-1V46a1,1,0,0,0-1-1H29a1,1,0,0,0-1,1v4A1,1,0,0,0,29,51Zm1-4h4v2H30Z" />
      <path d="M41.27,44.372a3.1,3.1,0,0,1-.645-2.816A8.612,8.612,0,0,0,41,39a8.887,8.887,0,0,0-.56-3.11,3,3,0,0,0-3.375-1.855A3.377,3.377,0,0,1,35,33.47V11.236l.653-1.306A.98.98,0,0,0,36,10h1a1,1,0,0,0,0-2H36V7h1a1,1,0,0,0,0-2H36V4h1a1,1,0,0,0,0-2H36a1,1,0,0,0-1-1H29a1,1,0,0,0-1,1H27a1,1,0,0,0,0,2h1V5H27a1,1,0,0,0,0,2h1V8H27a1,1,0,0,0,0,2h1a.98.98,0,0,0,.347-.07L29,11.236V30.522a8.81,8.81,0,0,0-5.788,6.51,9.294,9.294,0,0,0,.2,4.651,2.9,2.9,0,0,1-.669,2.681,11.971,11.971,0,0,0-2.618,9.387c.654,4.618,4.133,7.964,9.193,8.961A.987.987,0,0,0,30,63h2.073c.056,0,.113.01.168.01.079,0,.156-.009.235-.01H34a.988.988,0,0,0,.561-.185,11.847,11.847,0,0,0,5.918-2.769A10.606,10.606,0,0,0,44,52,12.048,12.048,0,0,0,41.27,44.372ZM31,20h2v2a1,1,0,0,0,0,2v2H31Zm2-2H31V16h2ZM31,28h2v2H31Zm2-14H31V12h2ZM30,39h4v2H30Zm3-2H31V32h2v1a1,1,0,0,0,0,2ZM30,3h4V8.764L33.382,10H30.618L30,8.764Zm3.186,57.973c-.3.023-.6.026-.9.027h-.408c-.355-.011-.712-.031-1.073-.073L30.22,58h3.56Zm5.962-2.42A9.573,9.573,0,0,1,35.3,60.6L35.98,57.2A1,1,0,0,0,35,56H29a1,1,0,0,0-.98,1.2l.662,3.313C25,59.475,22.593,56.95,22.1,53.472a9.995,9.995,0,0,1,2.181-7.836,4.88,4.88,0,0,0,1.035-4.549,7.171,7.171,0,0,1-.149-3.633A6.833,6.833,0,0,1,29,32.668V37a1,1,0,0,0-1,1v4a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V38a1,1,0,0,0-1-1V35.69a4.44,4.44,0,0,0,2.406.316,1.022,1.022,0,0,1,1.157.573A6.919,6.919,0,0,1,39,39a6.64,6.64,0,0,1-.291,1.981,5.1,5.1,0,0,0,1.018,4.664A9.9,9.9,0,0,1,42,52,8.52,8.52,0,0,1,39.148,58.553Z" />
      <path d="M36,53H28a1,1,0,0,0,0,2h8a1,1,0,0,0,0-2Z" />
    </g>
  </>
);

export default Guitar;
