import { ClassProps } from "@/lib/types/class-props";
import React, { useEffect } from "react";
import { EntityData } from "../../../lib/types/music-licensing";
import PlayButton from "../components/play-button";
import { useAudioController } from "../useAudioController";
import Image from "next/image";

export type EntityCardProps = {
  entity: EntityData;
  active?: boolean;
  onClick?: (entity: EntityData) => void;
} & ClassProps;

const EntityCard = ({
  entity,
  active,
  onClick,
  className,
}: EntityCardProps) => {
  const { status, audio, setCurrentUrl } = useAudioController(entity.url);

  const handlePlay = () => {
    if (status === "paused") {
      audio?.play();
      setCurrentUrl(entity.url);
    } else {
      audio?.pause();
    }
  };

  useEffect(() => {
    return () => {
      // Cleanup function to pause audio playback on component unmounts, for some reason status is paused but audio isn't so just set
      // for pausing always
      if (audio) {
        audio.pause();
      }
    };
  }, [audio]);

  return (
    <div
      className={`w-[280px] flex flex-col items-center gap-[10px] ${className}`}
      onClick={() => onClick?.(entity)}
    >
      <h3
        className={`font-righteous w-full text-center transition-opacity text-[34px] leading-[110%] ${
          active ? "opacity-100" : "opacity-0"
        }`}
      >
        {entity.type}
      </h3>

      <div className="rounded-[20px] relative flex flex-col items-start justify-end w-[280px] h-[300px] text-white transition-all">
        <div
          className={`
            z-[1000] bg-gradient-to-b from-black/0 to-black/80 rounded-b-[20px] w-full p-5
            ${active ? "opacity-100" : "opacity-0"}`}
        >
          <PlayButton
            status={status}
            className="mb-[10px]"
            onClick={handlePlay}
          />

          <p className="text-[16px] leading-[140%] mb-[6px]">{entity.name}</p>

          <p className="text-[12px] leading-[140%]">{entity.artist}</p>
        </div>

        <Image
          src={entity.imageUrl}
          alt={entity.name}
          className={`
            w-full h-full object-cover absolute inset-0 rounded-[20px] transition-all pointer-events-none z-0
            ${active ? "blur-0" : "blur-sm"}
          `}
          layout="fill"
        />
      </div>
    </div>
  );
};

export default EntityCard;
