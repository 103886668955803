import { ClassProps } from "@/lib/types/class-props";
import { cx } from "@/lib/utils/className.utils";
import React from "react";
import Icon from "../../icons/icon";
import FilledButton from "@/components/buttons/filled-button";
import MarketplaceAlt from "@/components/icons/lib/marketplace-alt";
import Section from "../components/section";
import { EntityData } from "@/lib/types/music-licensing";
import Link from "next/link";
import MusicLicensingCtaButton from "./music-licensing-cta-button";
import EntityCarousel from "./entity-carousel";

export type MusicLicensingProps = {
  musicLicenses: EntityData[];
  changeInterval: number;
} & ClassProps;

const MusicLicensing = ({
  className,
  musicLicenses,
  changeInterval,
}: MusicLicensingProps) => {
  return (
    <Section
      className={cx("flex w-full bg-yellow z-10 relative", className)}
      icon={
        <Icon
          icon={MarketplaceAlt}
          viewBox="0 0 34 34"
          className="fill-black w-[34px] h-[34px]"
        />
      }
      header={
        <h2 className="text-[54px] leading-[110%] font-righteous text-darkGreen text-center md:text-start">
          Commission-Free Music Licensing
        </h2>
      }
      carousel={
        <EntityCarousel
          entities={musicLicenses}
          changeInterval={changeInterval}
        />
      }
      footer={
        <>
          <div className="flex flex-col items-start gap-5 text-darkGreen/70">
            <p>
              Lutely&apos;s built-in marketplace allows artists and creators to
              do business directly and commission-free.
            </p>

            <p>
              From royalty-free to exclusive licensing (and everything in
              between), you set the terms, you set the prices.
            </p>
          </div>

          <div className="flex flex-row items-center gap-5">
            <Link href="/marketplace">
              <FilledButton variant="dark" className="font-righteous">
                Browse
              </FilledButton>
            </Link>

            <MusicLicensingCtaButton />
          </div>
        </>
      }
    />
  );
};

export default MusicLicensing;
