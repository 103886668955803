import api from "./api-config";

interface Waitlist {
  email: string;
  newsletter: boolean;
}

export const addToWaitlist = async ({ email, newsletter }: Waitlist) => {
  try {
    const res = await api.post("/waitlist", { email, newsletter });
    return res.status === 200;
  } catch (error) {
    console.log(error);

    return false;
  }
};

export const addToNewsletter = async (email: string) => {
  try {
    const res = await api.patch(`/waitlist/${email}`);
    return res.status === 200;
  } catch (error) {
    console.log(error);
    return false;
  }
};
