import { IconType } from "../icon-props";

const MarketplaceAlt: IconType = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="31"
    viewBox="0 0 35 31"
    fill="none"
  >
    <path
      d="M4.08522 4.01318H30.3352C31.3665 4.01318 32.2102 3.16943 32.2102 2.13818C32.2102 1.10693 31.3665 0.263184 30.3352 0.263184H4.08522C3.05397 0.263184 2.21022 1.10693 2.21022 2.13818C2.21022 3.16943 3.05397 4.01318 4.08522 4.01318ZM32.5102 7.38818C32.3415 6.52568 31.5727 5.88818 30.6727 5.88818H3.74772C2.84772 5.88818 2.07897 6.52568 1.91022 7.38818L0.0352209 16.7632C-0.189779 17.9257 0.691471 19.0132 1.87272 19.0132H2.21022V28.3882C2.21022 29.4194 3.05397 30.2632 4.08522 30.2632H19.0852C20.1165 30.2632 20.9602 29.4194 20.9602 28.3882V19.0132H28.4602V28.3882C28.4602 29.4194 29.304 30.2632 30.3352 30.2632C31.3665 30.2632 32.2102 29.4194 32.2102 28.3882V19.0132H32.5477C33.729 19.0132 34.6102 17.9257 34.3852 16.7632L32.5102 7.38818ZM17.2102 26.5132H5.96022V19.0132H17.2102V26.5132Z"
      fill="black"
    />
  </svg>
);

export default MarketplaceAlt;
